import { FC } from "react";
import { BrandWhyUs } from "types/models";
import WhyUsCard from "./why-us-card/why-us-card.component";
import "./why‐us.styles.scss";

interface Props {
  title?: string;
  titleHighlight?: string;
  summary?: string;
  whyUsDetails: BrandWhyUs[];
}

const WhyUs: FC<Props> = ({ whyUsDetails, title, titleHighlight, summary }) => {
  return (
    <section className="wu-section">
      <div className="wu-content">
        <div className="wu-texts">
          <h2 className="wu-summary">
            {title || "Why"} <span>{titleHighlight || "Us?"}</span>
          </h2>
          {summary && <p className="wu-description">{summary}</p>}
        </div>

        <div className="why-us-cards">
          {whyUsDetails.map((detail) => (
            <WhyUsCard key={detail.title} {...detail} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
