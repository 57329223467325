import classNames from "classnames";
import { IoCloseOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";

import brandLogoUrl from "assets/images/brand-logo.png";
import { appRoutes } from "constants/routes";
import { useNav } from "providers/nav.provider";

import ImageContainer from "components/shared/image-container/image-container.component";

import { brandName } from "constants/strings.constants";
import "./side-nav.styles.scss";
import SideNavLink from "./side-nav-link/side-nav-link.component";

const SideNav = () => {
  const { isSideNavOpen, toggleSideNav } = useNav();

  return (
    <nav className={classNames("sn-container", { open: isSideNavOpen })}>
      <div className={classNames("sn", { open: isSideNavOpen })}>
        <div className="sn-header">
          <NavLink to={appRoutes.home.path}>
            <div className="sn-brand">
              <ImageContainer
                height="32px"
                src={brandLogoUrl}
                alt={brandName}
                objectFit="contain"
              />
            </div>
          </NavLink>

          <IoCloseOutline
            size="32px"
            className="h-mobile-menu"
            onClick={toggleSideNav}
          />
        </div>
        <ul className="links">
          {Object.values(appRoutes)
            .filter((r) => r.quickLink)
            .map((route) => (
              <li key={route.path} className="nav-link-wrapper">
                <SideNavLink route={route} />
              </li>
            ))}
        </ul>
      </div>
    </nav>
  );
};

export default SideNav;
