import NextButton from "components/shared/buttons/next-button/next-button.component";
import { brandManufactures } from "constants/brand.constants";
import { FC } from "react";
import { BrandManufacture } from "types/models";
import './other-products.styles.scss';

interface Props {
  skipProduct?: BrandManufacture["id"];
}

const OtherProducts: FC<Props> = ({ skipProduct }) => {
  return (
    <section className="omp-section">
      <div className="omp-row">
        <h2 className="omp-summary">
          Our More<span> Products</span>
        </h2>
        <div className="omp-manufactures">
          {brandManufactures
            .filter((m) => m.id !== skipProduct)
            .map((m) => (
              <div
                key={m.id}
                className="omp-manufacture"
                style={{
                  backgroundImage: `url(${m?.imageUrl})`,
                }}
              >
                <div className="omp-dc-gradient" />
                <div className="omp-texts-row">
                  <h5 className="omp-title">{m.title.toLowerCase()}</h5>
                  <NextButton color="white" />
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default OtherProducts;
