import { createContext, PropsWithChildren, useContext, useState } from "react";
import { AppRoute } from "types/routes.types";

interface NavContextValue {
  isSideNavOpen: boolean;
  toggleSideNav: () => void;
  subRouteOpen: AppRoute | null;
  setSubRouteOpen: (route: AppRoute | null) => void;
}

const NavContext = createContext<NavContextValue | null>(null);

const NavProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [activeRoute, setActiveRoute] = useState<AppRoute | null>(null);

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };

  return (
    <NavContext.Provider
      value={{
        isSideNavOpen,
        toggleSideNav,
        subRouteOpen: activeRoute,
        setSubRouteOpen: (route) => setActiveRoute(route),
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

const useNav = () => {
  const context = useContext(NavContext);
  if (!context) {
    throw new Error("useSideNav must be used within a SideNavProvider");
  }
  return context;
};

export { NavProvider, useNav };
