import { useEffect, useState } from "react";

import { Direction } from "types/enums";
import mLogo from "assets/images/M.png";

import PrevButton from "components/shared/buttons/prev-button/prev-button.component";
import NextButton from "components/shared/buttons/next-button/next-button.component";

import "./projects.styles.scss";
import { useNavigate } from "react-router-dom";
import { appRoutes } from "constants/routes";
import useProjectService from "services/project/project.hooks";
import { BrandProject } from "types/models";

const Projects = () => {
  const [selectedProjectIndex, setSelectedProjectIndex] = useState<number>(0);

  const { getFeaturedProjects, data } = useProjectService();

  useEffect(() => {
    getFeaturedProjects();
  }, []);

  const handleSolutionChange = (direction: Direction): void => {
    setSelectedProjectIndex((prev) => {
      switch (direction) {
        case Direction.BACKWARD:
          return Math.max(0, prev - 1);
        case Direction.FORWARD:
          return Math.min(prev + 1, (data as BrandProject[]).length - 1);
      }
    });
  };

  const selectedProject = (data as BrandProject[])?.[selectedProjectIndex];

  const navigate = useNavigate();

  if (!selectedProject) return null;

  return (
    <section className="prj-section">
      <div className="prj-content">
        <div className="prj-texts">
          <p className="prj-sc-title">PROJECTS</p>
          <h2 className="prj-summary">
            Diverse <span>Solar Projects</span>
          </h2>

          <div>
            <h4 className="prj-title">{selectedProject.title}</h4>

            <ul className="prj-details">
              {selectedProject.summary.map((d) => (
                <li key={d} className="prj-detail">
                  {d}
                </li>
              ))}
            </ul>

            <div
              onClick={() =>
                navigate(`${appRoutes.projects.path}/${selectedProject.id}`)
              }
              className="prj-know-more-text"
            >
              Read More
            </div>
          </div>
        </div>

        <div className="prj-details-row">
          <div className="prj-img">
            <div
              className="prj-image"
              style={{ backgroundImage: `url(${selectedProject.imageUrl})` }}
            >
              <div
                className="prj-m-tag"
                style={{ backgroundImage: `url(${mLogo})` }}
              ></div>
            </div>
            <div className="prj-navigation-row desktop">
              <PrevButton
                onClick={() => handleSolutionChange(Direction.BACKWARD)}
              />
              <NextButton
                onClick={() => handleSolutionChange(Direction.FORWARD)}
              />
            </div>
          </div>
        </div>

        <div className="prj-navigation-row mobile">
          <PrevButton
            onClick={() => handleSolutionChange(Direction.BACKWARD)}
          />
          <NextButton onClick={() => handleSolutionChange(Direction.FORWARD)} />
        </div>
      </div>
    </section>
  );
};

export default Projects;
