import earthBgUrl from "assets/images/commercial/earth.png";

import About from "components/shared/about/about.component";
import CallUsBanner from "components/shared/call-us-banner/call-us-banner.component";
import Certificates from "components/commercial/certificates/certificates.component";
import Form from "components/commercial/form/form.component";
import OurProjects from "components/commercial/our-projects/our-projects.component";
import SolarBenefits from "components/shared/solar‐benefits/solar‐benefits.component";
import WhyUs from "components/commercial/why‐us/why‐us.component";
import Faqs from "components/homepage/faqs/faqs.component";
import Hero from "components/shared/hero/hero.component";
import Testimonials from "components/testimonials/testimonials.components";
import {
  commercialBenefits,
  commercialFaqs,
  commercialWhyUsStrings,
} from "constants/commercial.constants";
import { brandOfferings, solarBenefits } from "constants/brand.constants";
import TailoredOfferings from "components/shared/tailored-offerings/tailored-offerings.component";
import TextWithImage from "components/text-with-image/text-wth-image.component";
import TitleSummary from "components/shared/title-summary/title-summary.component";
import ServicesBenefits from "components/shared/service-benefits/service-benefits.component";

const Commercial = () => {
  return (
    <>
      <Hero
        backgroundUrl={'https://res.cloudinary.com/dsmpun8yd/image/upload/v1727774775/blog_cover-2_hmha27.webp'}
        titlePart1="Solar Panels For"
        titleHighlight="Commercial & Industrial Building"
      />
      <About
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1727774775/blog_cover-2_hmha27.webp"
        title="Optimize Your Business with"
        titleHighlight="Solar Energy"
        summary="Enhance your business’s energy efficiency with commercial rooftop
            solar solutions. By installing solar panels for business, you reduce
            operational costs and contribute to a sustainable future. Our
            industrial solar power installations are designed to optimize energy
            use, ensuring maximum savings and minimal environmental impact. Commercial solar systems are designed to convert sunlight into electricity for businesses and industrial facilities. Unlike residential systems, these installations are tailored to meet the larger energy demands of commercial operations. By utilizing commercial solar panels, businesses can generate significant amounts of electricity, allowing them to operate more sustainably and cost-effectively."
      />
      <CallUsBanner bgImage={earthBgUrl} />

      <WhyUs whyUsDetails={commercialWhyUsStrings} />
      <Certificates />
      <TextWithImage
        title="Solar Panels for "
        titleHighlight="Industrial Buildings"
        textAlign="left"
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1727524886/IMG_5873_1_udul63_rqsxdr.webp"
        text={`When considering solar energy solutions, the type of solar panels for commercial buildings is a crucial factor. Industrial solar panels are specifically designed to handle the higher energy loads typical of larger operations. The advantages of using solar panels for industrial buildings include:


- **High Energy Output**: Industrial solar panels can produce more electricity, making them ideal for large facilities.

- **Durability**: These panels are built to withstand harsh weather conditions, ensuring long-term reliability

- **Scalability**: Businesses can easily expand their solar installations as their energy needs grow.`}
      />
      <TailoredOfferings
        title="Our Tailored "
        offering={brandOfferings}
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1727773184/residential-house-with-rooftop-covered-with-solar-photovoltaic-panels-producing-clean-ecological-electrical-energy-suburban-rural-area-concept-autonomous-home__1_-removebg-preview_1_c94mts.webp"
        titleHighlight="Solar Offerings"
      />

      <TitleSummary
        title="The Role of "
        titleHighlight="Commercial Solar Providers"
        summary="Choosing the right commercial solar providers is critical to the success of any solar project. These providers not only supply the necessary equipment but also offer essential services that can impact the overall efficiency and effectiveness of the solar installation"
      />

      <ServicesBenefits
        title="What to Look for in "
        titleHighlight="Commercial Solar Providers"
        benefits={commercialBenefits}
        hideForm
        titleSize="small"
      />

      <SolarBenefits
        benefits={solarBenefits}
        title="Benefits of Commercial "
        titleHighlight="Solar Panel"
      />
      <Form />
      <OurProjects />
      <Testimonials hideCertificates showImage />
      <Faqs faqs={commercialFaqs} />
    </>
  );
};

export default Commercial;
