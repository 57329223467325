import "./all-projects.styles.scss";
import ProjectCard from "components/shared/project-card/project-card.component";
import useProjectService from "services/project/project.hooks";
import { useEffect } from "react";
import { BrandProject } from "types/models";

const AllProjects = () => {
  const { getProjects, data } = useProjectService();

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <section className="ap-section">
      <div className="ap-content">
        <h3 className="ap-title">
          Our <span>Projects</span>
        </h3>
        <div className="ap-grid">
          {(data as BrandProject[])?.map((p) => (
            <ProjectCard project={p} key={p.id} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default AllProjects;
