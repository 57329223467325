import { BrandProject } from "types/models";
import { FC } from "react";

import "./project-card.styles.scss";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { appRoutes } from "constants/routes";

interface Props {
  project: BrandProject;
  className?: string;
}

const ProjectCard: FC<Props> = ({ project, className }) => {
  const navigate = useNavigate();

  return (
    <div
      className={classNames("pc-card", className)}
      key={project.id}
      style={{ backgroundImage: `url(${project.imageUrl})` }}
    >
      <div className="pc-dc-gradient" />
      <div className="pc-dc-texts">
        <h4 className="pc-dc-title">{project.title}</h4>

        <ul className="pc-dc-description">
          {project.highlights.map((d) => (
            <li key={d} className="pc-dc-detail">
              {d}
            </li>
          ))}
        </ul>
        <span
          className="pc-know-more"
          onClick={() => navigate(`${appRoutes.projects.path}/${project.id}`)}
        >
          Read More
        </span>
      </div>
    </div>
  );
};

export default ProjectCard;
