import { ButtonHTMLAttributes, FC } from "react";
import { MdArrowRightAlt } from "react-icons/md";

import "./get-in-touch-button.styles.scss";

type LocalProps = ButtonHTMLAttributes<HTMLButtonElement>;

const GetInTouchButton: FC<LocalProps> = ({ ...buttonProps }) => {
  const handleClick = () => {
    const formSection = document.getElementById("form");
    if (formSection) {
      formSection.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  return (
    <button onClick={handleClick} className="git-cta" {...buttonProps}>
      Get In Touch
      <MdArrowRightAlt fontSize="20px" color="inherit" />
    </button>
  );
};

export default GetInTouchButton;
