import BlogCard from "components/homepage/blogs/blog-card/blog-card.component";

import "./related-blogs.styles.scss";
import useBlogService from "services/blog/blog.hooks";
import { FC, useEffect } from "react";
import { BlogDetail, BlogType } from "types/models";

interface Props {
  blogType: BlogType;
}

const RelatedBlogs: FC<Props> = ({ blogType }) => {
  const { data: blogs, getLatestBlogs } = useBlogService();

  useEffect(() => {
    getLatestBlogs();
  }, []);

  return (
    <section className="bdc-related-posts">
      <h3 className="bdc-related-posts-title">Related Posts</h3>
      <div className="bdc-rp-grid">
        {(blogs as BlogDetail[])
          ?.filter((blog) => blog.blogType?.id === blogType.id)
          .slice(0, 3)
          .map((blog) => (
            <BlogCard key={blog.id} blog={blog} showTag />
          ))}
      </div>
    </section>
  );
};

export default RelatedBlogs;
