import { FiPhone } from "react-icons/fi";
import { GoThumbsup } from "react-icons/go";
import { IoIosStarOutline } from "react-icons/io";
import { Benefit, BrandFaq, BrandWhyUs, Offering } from "types/models";
import { ServiceBenefit } from "types/services.types";

export const housingSocietiesFAQS: BrandFaq[] = [
  {
    id: "faq-1",
    title:
      "Is solar power for housing societies in Bhopal suitable for all types of buildings?",
    description:
      "Yes, rooftop solar for societies can be customized to fit various building types and sizes, making it a versatile solution for housing societies.",
  },
  {
    id: "faq-2",
    title: "How does solar energy benefit housing societies financially?",
    description:
      "By reducing electricity bills and increasing property value, solar energy for housing societies provides significant financial benefits.",
  },
  {
    id: "faq-3",
    title:
      "What are the maintenance requirements for solar panels in housing societies?",
    description:
      "Solar panels for housing societies require minimal maintenance, mainly periodic cleaning and inspections to ensure optimal performance.",
  },
];

export const housingSocietiesWhyUsStrings: BrandWhyUs[] = [
  {
    title: "Expertise",
    icon: <IoIosStarOutline />,
    description:
      "With decades of experience in solar technology and installation, we bring unparalleled knowledge & reliability.  We specialize in solar energy for housing societies with a proven track record of successful installations.",
  },
  {
    title: "Quality",
    icon: <GoThumbsup />,
    description:
      "We utilise only high-performance solar panels and components, ensuring long-lasting and efficient energy solutions. Our team provides customized rooftop solar for societies solutions, ensuring optimal energy generation tailored to your needs.",
  },
  {
    title: "Support",
    icon: <FiPhone />,
    description:
      "Our comprehensive maintenance and customer service ensure that your solar systems run smoothly. We offer comprehensive support from consultation to maintenance, ensuring your solar power for housing societies performs efficiently.",
  },
];

export const housingBenefits: ServiceBenefit[] = [
  {
    title: "Significant Cost Savings",
    description:
      "Solar power drastically reduces electricity bills for common areas like lighting, lifts, and water pumps, allowing societies to save substantially on monthly expenses.",
  },
  {
    title: "Environmental Impact",
    description:
      "By switching to solar energy, housing societies contribute to reducing carbon emissions, promoting a greener and more sustainable community.",
  },
  {
    title: "Increased Property Value",
    description:
      "Solar installations enhance the overall value of the property, making it more attractive to potential buyers.",
  },
  {
    title: "Government Incentives",
    description:
      "Housing societies can benefit from various government subsidies, rebates, and incentives, making solar installation more affordable and accessible.",
  },
];

export const housingOfferings: Offering[] = [
  {
    id: "1",
    title: "Energy Assessment:",
    description:
      "Evaluating the energy consumption patterns of the housing society to determine the optimal system size.",
  },
  {
    id: "4",
    title: "System Design:",
    description:
      "Creating a customized solar solution that aligns with the community's needs and architectural features.",
  },
  {
    id: "2",
    title: "Installation",
    description: `Professional installation ensures that the solar panels are securely mounted and configured for maximum efficiency.`,
  },
  {
    id: "3",
    title: "Maintenance and Support",
    description:
      " Ongoing support and maintenance are essential for the long-term performance of the solar system.",
  },
];

export const residentialSolarBenefits: Benefit[] = [
  {
    id: "1",
    title: "Space Efficiency",
    description:
      "Utilizing rooftop space allows housing societies to generate energy without impacting available land for other uses.",
    imageUrl: "",
  },

  {
    id: "2",
    title: "Environmentally Friendly",
    description:
      "Solar energy is a renewable resource, meaning it reduces your home's carbon footprint.",
    imageUrl: "",
  },

  {
    id: "3",
    title: "Increased Property Value",
    description:
      "Homes equipped with residential solar panels often sell at higher prices and faster than those without.",
    imageUrl: "",
  },

  {
    id: "4",
    title: "Energy Independence",
    description:
      "Installing a solar rooftop system gives you greater control over your energy supply, reducing dependence on traditional power grids",
    imageUrl: "",
  },
];

export const housingServiceBenefits: ServiceBenefit[] = [
  {
    title: "Space Efficiency",
    description: `Utilizing rooftop space allows housing societies to generate energy without impacting available land for other uses.`,
  },
  {
    title: "Aesthetic Integration",
    description:
      "Modern solar panels can be designed to blend seamlessly with the architectural style of the buildings",
  },
  {
    title: "Community Resilience",
    description:
      "By generating their own power, housing societies can become more resilient against energy price fluctuations and grid outages.",
  },
  {
    title: "Solar Subsidy for Housing Society",
    description:
      "Navigating the financial aspect of solar energy installation can be daunting. Fortunately, many governments offer solar subsidies for housing societies to encourage the adoption of renewable energy.",
  },
];