import Markdown from "react-markdown";
import "./machinery-setup.styles.scss";
import { FC } from "react";

interface Props {
  title: string;
  titleHighlight: string;
  counts: { count: string; title: string }[];
  content: string;
  imageUrl?: string;
}

const MachinerySetup: FC<Props> = ({
  content,
  counts,
  title,
  imageUrl,
  titleHighlight,
}) => {
  return (
    <section className="ms-wrapper">
      <div className="ms-container">
        <h2 className="ms-summary">
          {`${title} `} <span>{titleHighlight}</span>
        </h2>
        <Markdown className="ms-description">{content}</Markdown>
      </div>

      <div className="ms-img" style={{ backgroundImage: `url(${imageUrl})` }}>
        <div className="ms-counter-row">
          <div className="ms-counter">
            <h3 className="ms-count">{counts[0].count}</h3>
            <p className="ms-title">{counts[0].title}</p>
          </div>
          <div className="ms-counter">
            <h3 className="ms-count primary">{counts[1].count}</h3>
            <p className="ms-title">{counts[1].title}</p>
          </div>
          <div className="ms-counter">
            <h3 className="ms-count">{counts[2].count}</h3>
            <p className="ms-title">{counts[2].title}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MachinerySetup;
