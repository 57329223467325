import './description.styles.scss'

const Description = () => {
    return  <p className='pp-description'>
    At MPL India, we pride ourselves on delivering high-quality, sustainable
    energy solutions through solar installations. With over 100 successful
    projects completed, we have been at the forefront of empowering
    residential, commercial, industrial, and housing societies with clean,
    renewable energy. With a team of expert engineers and technicians, we
    offer end-to-end services from planning and designing to installation
    and maintenance. Our mission is to help you reduce your carbon footprint
    while benefiting from the financial advantages of solar energy.
  </p>
}

export default Description