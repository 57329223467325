import { useNav } from "providers/nav.provider";
import { FC, useEffect } from "react";
import { IoChevronDown } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { AppRoute } from "types/routes.types";

interface Props {
  route: AppRoute;
}

const HeaderLink: FC<Props> = ({ route }) => {
  const { subRouteOpen, setSubRouteOpen } = useNav();

  const toggleSubRoutes = (route: AppRoute | null) => setSubRouteOpen(route);

  const isOpenSubRoute = route.path === subRouteOpen?.path;

  const handleNavigation = (
    evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    route: AppRoute
  ) => {
    if (route.subRoutes?.length) {
      evt.preventDefault();
      toggleSubRoutes(isOpenSubRoute ? null : route);
    }
  };

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isSubRouteOrNavLink = target.closest(
        ".nav-link, .sub-routes, .sub-route"
      );

      if (!isSubRouteOrNavLink) {
        setSubRouteOpen(null);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <>
      <NavLink
        className={({ isActive }) => `${isActive ? "route-active" : ""}`}
        onClick={(e) => handleNavigation(e, route)}
        to={route.path}
      >
        {route.title}
      </NavLink>
      {!!route.subRoutes?.length && <IoChevronDown />}
      {!!route.subRoutes?.length && isOpenSubRoute && (
        <div className="sub-routes">
          {route.subRoutes?.map((sb) => (
            <NavLink
              onClick={() => setSubRouteOpen(null)}
              className={({ isActive }) => {
                return isActive ? "sub-route active" : "sub-route";
              }}
              to={sb.path}
            >
              {sb.title}
            </NavLink>
          ))}
        </div>
      )}
    </>
  );
};

export default HeaderLink;
