import WireOffering from "components/fencing-wire/wire-offering/wire-offering.component";
import Blogs from "components/homepage/blogs/blogs.component";
import Faqs from "components/homepage/faqs/faqs.component";
import About from "components/shared/about/about.component";
import Hero from "components/shared/hero/hero.component";
import MachinerySetup from "components/machinery-setup/machinery-setup.component";
import OtherProducts from "components/solar-panel-structure/other-products/other-products.component";
import Testimonials from "components/testimonials/testimonials.components";
import { brandFaqs } from "constants/brand.constants";
import Form from "components/commercial/form/form.component";
import SolarBenefits from "components/shared/solar‐benefits/solar‐benefits.component";
import { fencingBenefits, fencingFaqs } from "constants/fencing.constants";

const FencingWire = () => {
  return (
    <>
      <Hero
        backgroundUrl="https://res.cloudinary.com/dsmpun8yd/image/upload/v1727836901/Rectangle_6440_1_pazrcu.webp"
        titlePart1="Fencing"
        titleHighlight="Wire"
      />
      <About
        title="Reliable Fencing Wire Solutions for Every "
        titleHighlight="Environment"
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1727836985/Subtract_pumoif.png"
        summary={`At Mittal Plastomet Ltd., we manufacture high-quality fencing wires tailored for industrial, agricultural, and residential purposes. Our fencing wires are designed to withstand harsh environments, providing long-lasting durability and security. We offer various types of fencing solutions, including barbed wire, chain link fencing, and concertina wire. All our products are crafted using top-grade materials, ensuring robust protection and low maintenance. Whether it's for large-scale farming, industrial premises, or residential boundaries, our fencing wires provide the ultimate solution for all your fencing needs.`}
      />
      <WireOffering />
      <MachinerySetup
        title="MPL India: Your Partner for Comprehensive"
        titleHighlight="Fencing Wire Solutions"
        content="MPL India goes beyond simply connecting you with fencing wire suppliers. We offer a comprehensive service, including:
* **Technical expertise to help you choose the right wire for your needs.**
* **Access to a diverse range of fencing wire products.**
* **Competitive pricing and reliable supply chains.**
"
        counts={[
          { count: "5+", title: "Items" },
          { count: "200 Tons", title: "Production Per Month" },
          { count: "50+", title: "Team Members" },
        ]}
        imageUrl="https://res.cloudinary.com/dsmpun8yd/image/upload/v1728294510/IMG_8059_mcwwe3.webp"
      />
      <SolarBenefits
        title="Benefits of Choosing MPL India for Your"
        titleHighlight="Fencing Wire Needs"
        benefits={fencingBenefits}
      />
      <OtherProducts skipProduct="fencing" />
      <Testimonials />
      <Form />
      <Faqs faqs={fencingFaqs} />
      <Blogs />
    </>
  );
};

export default FencingWire;
