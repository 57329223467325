/**
 * Converts a date string from "yyyy-mm-dd" to "dd mmmm, yyyy" format.
 *
 * @param dateStr - The date string to convert.
 * @returns The converted date string.
 */
export const formatDate = (date: Date): string => {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  return `${day} ${month}, ${year}`;
};
