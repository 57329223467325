import Form from "components/commercial/form/form.component";
import Blogs from "components/homepage/blogs/blogs.component";
import Faqs from "components/homepage/faqs/faqs.component";
import MachinerySetup from "components/machinery-setup/machinery-setup.component";
import PreformOfferings from "components/pet-preform/preform-offerings/preform-offerings.component";
import About from "components/shared/about/about.component";
import Hero from "components/shared/hero/hero.component";
import InfoPanel from "components/shared/info-panel/info-panel.component";
import SolarBenefits from "components/shared/solar‐benefits/solar‐benefits.component";
import TailoredOfferings from "components/shared/tailored-offerings/tailored-offerings.component";
import OtherProducts from "components/solar-panel-structure/other-products/other-products.component";
import Testimonials from "components/testimonials/testimonials.components";
import { brandFaqs } from "constants/brand.constants";
import {
  petPreformBenefits,
  petPreformFaqs,
  petPreformOffering,
} from "constants/pet-preform.constants";

const PetPreform = () => {
  return (
    <>
      <Hero
        backgroundUrl="https://res.cloudinary.com/dsmpun8yd/image/upload/v1728302596/Rectangle_6440_2_d7ba3h.webp"
        titlePart1="PET"
        titleHighlight="Preform"
      />
      <About
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1728837843/Subtract-2_dozdkn.png"
        title="High-Quality Pet Preforms Tailored for "
        titleHighlight="Every Industry"
        summary={`At Mittal Plastomet Ltd., we specialize in manufacturing high-quality PET Preforms designed for a range of industries, including beverages, pharmaceuticals, and cosmetics. Our preforms are made from top-grade PET material, ensuring durability, clarity, & strength. With cutting-edge manufacturing processes, we deliver precise and reliable preforms that meet the needs of our customers. We offer flexible solutions that align with your packaging requirements. Trust Mittal Plastomet for eco-friendly, high-performance PET preforms that enhance your product’s shelf life and appeal.`}
      />
      <PreformOfferings />
      <TailoredOfferings
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1728302463/Hcd49c777667e43fc92312dbef2a7aa054_2_v5nkvh.png"
        offering={petPreformOffering}
        title="Selecting the right"
        titleHighlight="PET preform manufacturer depends on several factor"
      />
      <MachinerySetup
        title="PET Preform Production and"
        titleHighlight="PET Preforms in Bhopal"
        content="
* **Standard Neck PET Preforms (SNPP):** These are the most common type, used for a wide range of bottled beverages, water bottles, and food containers.
* **Wide Mouth PET Preforms (WMPP):** Ideal for products requiring wider openings, such as jars, food containers, and personal care products.
* **Hot Fill PET Preforms:** Designed to withstand higher temperatures, suitable for hot-filled beverages and sauces.
* **Barrier PET Preforms:** Offer enhanced protection against oxygen or moisture for products like sensitive beverages or pharmaceuticals.
"
        counts={[
          { count: "20+", title: "Varieties" },
          { count: "350+", title: "Happy Customers" },
          { count: "50+", title: "Team Members" },
        ]}
        imageUrl="https://res.cloudinary.com/dsmpun8yd/image/upload/v1728297573/IMG_7266_1_1_xwprnt.webp"
      />
      <InfoPanel
        details={[
          "MPL India connects you with the best PET preform manufacturers in Bhopal and across India. We understand your specific requirements and help you find a supplier that delivers:",
          `* **High-quality PET preforms that meet your exact specifications**
  * **Competitive pricing and a reliable supply chain**
  * **Expert assistance and technical support**
  * **Commitment to sustainability and responsible packaging solutions**`,
        ]}
        title="MPL India: Your Partner for PET Preform Solutions"
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1728302208/Bottle-Grade-Pet-Chips-Pet-Plastic-Raw-Material-Price-Virgin-Pet-Resin_1_cf4kjc.png"
      />
      <SolarBenefits
        benefits={petPreformBenefits}
        title="Benefits of Choosing MPL India for"
        titleHighlight="Your PET Preform Needs"
      />
      <OtherProducts skipProduct="fencing" />
      <Testimonials />
      <Form />
      <Faqs faqs={petPreformFaqs} />
      <Blogs />
    </>
  );
};

export default PetPreform;
