import {
  Benefit,
  BrandFaq,
  Offering,
  PetPreform,
  TabularData,
} from "types/models";

export const petPreformOfferings: PetPreform[] = [
  { name: "Water Preform", imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728301194/Rectangle_6500_bz5sb6.png" },
  { name: "Carbonated Drinks", imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728301194/Rectangle_6503_ofozfb.png" },
  { name: "Oil Preform", imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728301192/Rectangle_6504_bi3kzv.png" },
  { name: "Pharmacy Preform", imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728301191/Rectangle_6505_wnwf15.png" },
  { name: "Dairy Preform", imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728301195/Rectangle_6506_gszb9z.png" },
  { name: "Cosmetic Preform", imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728301189/Rectangle_6507_fqwpwk.png" },
  { name: "Juice Preform", imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728301188/Rectangle_6508_yezriu.png" },
  { name: "Liquor Preform", imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728301188/Rectangle_6509_uyf1nb.png" },
  { name: "Sport Drinks", imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728301187/Rectangle_6510_q32hef.png" },
  { name: "20 lts Preform", imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728301187/Rectangle_6511_q2riai.png" },
  { name: "Preform for Jar", imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728301187/Rectangle_6514_lary79.png" },
  { name: "Customized Preform", imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728301187/Rectangle_6513_tagt96.png" },
];

export const preformData: TabularData = {
  "NECK TYPE": [
    "3 Start / Alaska",
    "3 Start / Alaska",
    "3 Start / Alaska",
    "3 Start / Alaska",
    "3 Start / Alaska",
    "3 Start / Alaska",
    "3 Start / Alaska",
    "3 Start / Alaska",
    "3 Start / Alaska",
    "3 Start / Alaska",
    "CTC",
    "CTC",
    "PCO 1810 (Long Neck)",
    "PCO 1881 (Short Neck)",
    "PCO 1881 (Short Neck)",
    "ROPP",
  ],
  "NECK SIZE": [
    "28MM",
    "28MM",
    "28MM",
    "28MM",
    "28MM",
    "28MM",
    "28MM",
    "28MM",
    "28MM",
    "28MM",
    "28MM",
    "38MM",
    "28MM",
    "28MM",
    "28MM",
    "46MM",
  ],
  "WEIGHT GM": [
    "21GM",
    "19.5GM",
    "18.4GM",
    "18GM",
    "17.5GM",
    "13.3GM",
    "12.4GM",
    "9.5GM",
    "8.8GM",
    "7.2GM",
    "24GM",
    "52GM",
    "22GM",
    "10.3GM",
    "12.5GM",
    "76.5GM",
  ],
  PACKING: [
    "25 KG",
    "25 KG",
    "25 KG",
    "25 KG",
    "25 KG",
    "25 KG",
    "25 KG",
    "25 KG",
    "25 KG",
    "25 KG",
    "25 KG",
    "25 KG",
    "25 KG",
    "25 KG",
    "25 KG",
    "20 KG",
  ],
  APPLICATION: [
    "1000ML, Water / Oil",
    "1000ML, Water / Oil",
    "1000ML, Water / Oil",
    "1000ML, Water / Oil",
    "1000ML, Water / Oil",
    "500ML, Water / Oil",
    "500ML, Water / Oil",
    "250ML, 200ML Water / Oil",
    "250ML, 200ML Water / Oil",
    "100ML Juice / Oil",
    "1000ML Juice / Oil",
    "2000ML Oil",
    "1000ML Phenyl / Oil",
    "Soft Drink / Soda",
    "Soft Drink / Soda",
    "5 LTS Phenyl / Acid/ Oil",
  ],
};

export const petPreformOffering: Offering[] = [
  {
    description:
      "Ensure the preform meets the size, weight, and neck finish requirements of your desired bottle or container.",
    id: "01",
    title: "Preform Type and Specifications",
  },
  {
    description:
      "High-quality PET ensures durability, clarity, and recyclability of the final product.",
    id: "02",
    title: "Material Quality",
  },
  {
    description:
      "Choose a manufacturer with the capacity to meet your production volume needs.",
    id: "03",
    title: "Production Capacity",
  },
  {
    description:
      "Look for manufacturers with certifications for food-grade PET and adherence to relevant safety standards.",
    id: "04",
    title: "Certifications",
  },
];

export const petPreformBenefits: Benefit[] = [
  {
    description:
      "We offer a wide range of PET preform production with diverse specifications to meet your unique needs.",
    title: "Extensive Capabilities:",
    id: "0",
    imageUrl: "",
  },
  {
    description:
      "Our manufacturing processes are built around strict quality control to ensure every PET preform meets superior industry standards.",
    title: "Quality Assurance:",
    id: "01",
    imageUrl: "",
  },
  {
    description:
      "We strive to connect you with competitive fencing wire suppliers to By managing our entire production process in-house, we offer high-quality PET preforms at competitive prices.meet your budget requirements",
    title: "Cost-Effectiveness",
    id: "03",
    imageUrl: "",
  },
  {
    description:
      "We are committed to responsible production and recycling practices, ensuring that our PET preforms contribute to a sustainable future.",
    title: "Sustainability Focus",
    id: "04",
    imageUrl: "",
  },
];

export const petPreformFaqs: BrandFaq[] = [
  {
    id: "1",
    title: "What is a PET preform?",
    description:
      "A PET preform is a plastic product made from polyethylene terephthalate (PET) that is molded into a shape that will be later blown into a finished bottle or container. Preforms are used in the manufacturing process for bottles used in water, soft drinks, juices, and other beverages, as well as for non-food products like detergents and personal care products.",
  },
  {
    id: "2",
    title: "What sizes and weights of preforms do you manufacture?",
    description:
      "We offer a wide range of PET preforms with varying neck sizes, weights, and colors. Our preforms are suitable for different bottle capacities from small bottles to larger containers. Please contact us for custom specifications.",
  },
  {
    id: "3",
    title: "What applications are PET preforms used for?",
    description:
      "Our PET preforms are used for manufacturing bottles for various industries, including: \n* • Beverages (water, juices, carbonated drinks), \n* • Personal care products (shampoos, lotions), \n* • Household products (cleaners, detergents), \n* • Food packaging (sauces, oils)",
  },
  {
    id: "4",
    title: "Can I order custom preforms tailored to my specific requirements?",
    description:
      "Yes, we specialize in custom preform manufacturing. We can create preforms according to your specific design, weight, neck size, and color requirements.",
  },
  {
    id: "5",
    title: "What are the benefits of using PET for preforms?",
    description:
      "PET (Polyethylene Terephthalate) is known for its excellent strength, light weight, clarity, and recyclability. It provides durability, safety for food-grade applications, and is environmentally friendly due to its recyclable nature.",
  },
  {
    id: "6",
    title: "Do you offer eco-friendly or recyclable PET preforms?",
    description:
      "Yes, all our PET preforms are made from 100% recyclable PET material. We also offer preforms made from recycled PET (rPET) for customers seeking sustainable packaging solutions.",
  },
  {
    id: "7",
    title:
      "What quality control measures do you have in place for PET preform manufacturing?",
    description:
      "We follow strict quality control measures at every stage of the manufacturing process. This includes preform testing for weight, dimensions, clarity, and impact resistance to ensure that the preforms meet industry standards and customer specifications.",
  },
];
