import "./journey.styles.scss";

const timeline = [
  {
    id: 1,
    date: "March, 2014",
    title: "Foundation of Mittal Plastomet",
    summary:
      "Mittal Plastomet Ltd. was established with a vision to manufacture high-quality industrial products, laying the groundwork for excellence in innovation & sustainability.",
  },
  {
    id: 2,
    date: "March, 2014",
    title: "PET Preforms Division Launched",
    summary:
      "Mittal Plastomet expanded its operations with the introduction of PET preforms, catering to packaging industries.",
  },
  {
    id: 3,
    date: "December, 2020",
    title: "Expansion into Fencing Solutions",
    summary:
      "The company expanded its operations into fencing solutions, specializing in manufacturing products that play a vital role in supporting the agricultural commodity sector.",
  },
  {
    id: 4,
    date: "April, 2023",
    title: "Expansion into Solar Solutions",
    summary:
      "Mittal Plastomet is now a leading provider of Solar solutions, serving over 500 clients & continuing to innovate in solar and it’s manufacturing sector.",
  },
];

const Journey = () => {
  return (
    <section className="journey-container">
      <div className="journey-content">
        <h3 className="journey-title">
          Our <span>Journey</span>
        </h3>
        <div className="timeline-container">
          {timeline.map((t) => (
          <div key={t.id} className="timeline-item">
            <div className="timeline-card">
              <div className="timeline-circle-wrapper">
                <div className="timeline-circle" />
              </div>
              <p className="tc-date">{t.date}</p>
              <h5 className="tc-title">{t.title}</h5>
              <p className="tc-summary">{t.summary}</p>
            </div>
          </div>
        ))}
        </div>
      </div>
    </section>
  );
};

export default Journey;
