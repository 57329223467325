import { structureOfferings } from "constants/struture.constants";
import "./structure-offerings.styles.scss";
import { SolarStructure } from "types/models";
import { FC } from "react";

interface SOCProps {
  structure: SolarStructure;
}
const StructureOfferingCard: FC<SOCProps> = ({ structure }) => {
  return (
    <div className="sof-card">
      <div
        className="sof-img"
        style={{ backgroundImage: `url(${structure.image})` }}
      />
      <p className="sof-item-name">{structure.name}</p>
      <div className="sof-item-details">
        {structure.attributes.map((a) => (
          <p key={a} className="sof-i-detail">
            {a}
          </p>
        ))}
      </div>
    </div>
  );
};

const StructureOfferings = () => {
  return (
    <section className="sof-wrapper">
      <div className="sof-container">
        <h2 className="sof-summary">
          Our Solar Panel Mounting <span>Structure Offerings</span>
        </h2>

        <div className="sof-grid">
          {structureOfferings.map((s) => (
            <StructureOfferingCard key={s.id} structure={s} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default StructureOfferings;
