import { FaLinkedinIn, FaInstagram, FaFacebookF } from "react-icons/fa";

import brandLogoUrl from "assets/images/brand-logo.png";
import solarBg from "assets/images/solar.png";
import {
  brandManufacturing,
  brandName,
  brandOffice,
  brandPhone,
} from "constants/strings.constants";
import { externalRoutes } from "constants/routes";

import ImageContainer from "components/shared/image-container/image-container.component";
import GetInTouchButton from "components/shared/get-in-touch-button/get-in-touch-button.component";
import FooterContactDetail from "./footer-contact-detail/footer-contact-detail.component";

import "./footer.styles.scss";

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="f-bg" style={{ backgroundImage: `url(${solarBg})` }} />
        <div className="f-brand">
          <ImageContainer
            width="100%"
            height="32px"
            src={brandLogoUrl}
            alt={brandName}
            objectFit="contain"
          />
          <p className="f-brand-name">{brandName}</p>
        </div>

        <p className="f-tagline">
          Expertly Crafted, <span>Sustainably Sourced</span>
        </p>

        <div className="f-cta">
          <GetInTouchButton />
        </div>

        <div className="f-contacts-row">
          <FooterContactDetail title="CONTACT">
            <a style={{ whiteSpace: "nowrap" }} href={`tel:${brandPhone}`}>
              +91-{brandPhone}
            </a>
          </FooterContactDetail>
          <FooterContactDetail className="address" title="OFFICE">
            {brandOffice}
          </FooterContactDetail>
          <FooterContactDetail title="MANUFACTURING" className="address">
            {brandManufacturing}
          </FooterContactDetail>
        </div>

        <div className="f-links">
          <a rel="noreferrer" href={externalRoutes.linkedIn} target="_blank">
            <FaLinkedinIn size={18} />
          </a>
          <a rel="noreferrer" href={externalRoutes.instagram} target="_blank">
            <FaInstagram size={18} />
          </a>
          <a rel="noreferrer" href={externalRoutes.facebook} target="_blank">
            <FaFacebookF size={18} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
