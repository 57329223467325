import { FiPhone } from "react-icons/fi";
import { GoThumbsup } from "react-icons/go";
import { IoIosStarOutline } from "react-icons/io";
import { Benefit, BrandFaq, BrandWhyUs } from "types/models";
import { ServiceBenefit } from "types/services.types";

export const megawattProjectsBenefits: ServiceBenefit[] = [
  {
    title: "Attractive Returns",
    description: `With the government’s support and incentives, investors can achieve stable and competitive returns on their investments.`,
  },
  {
    title: "Government Incentives",
    description: `Investors benefit from various financial incentives, including subsidies, tax benefits, and accelerated depreciation. `,
  },
  {
    title: "Grid Connectivity",
    description: `India is investing in improving grid infrastructure to support renewable energy integration. This ensures that solar power generated from megawatt-scale plants can be effectively distributed and utilized.`,
  },
  {
    title: "Long-Term Growth Potential",
    description: `As India continues to develop and expand its energy infrastructure, the growth potential for solar power remains high. `,
  },
];

export const megawattWhyUsStrings: BrandWhyUs[] = [
  {
    title: "Expertise",
    icon: <IoIosStarOutline />,
    description:
      "With decades of experience in solar technology and installation, we bring unparalleled knowledge & reliability to every project, ensuring top-notch service & effective energy solutions for all business types.",
  },
  {
    title: "Quality",
    icon: <GoThumbsup />,
    description:
      "We utilise only high-performance solar panels and components, ensuring long-lasting and efficient energy solutions. Our commitment to quality guarantees maximum energy output and system durability.",
  },
  {
    title: "Support",
    icon: <FiPhone />,
    description:
      "Our comprehensive maintenance and customer service ensure that your solar systems run smoothly. We offer prompt support and regular maintenance to keep your systems performing optimally.",
  },
];

export const megawattFaqs: BrandFaq[] = [
  {
    id: "faq-1",
    title: "What is a megawatt-scale solar power project?",
    description:
      "A megawatt-scale solar power project is a large-scale solar installation with a capacity measured in megawatts (MW). These projects are designed to generate significant amounts of electricity, typically ranging from 1 MW to several hundred MW, and are often connected to the grid.",
  },
  {
    id: "faq-2",
    title:
      "What are the key components of a megawatt-scale solar power project?",
    description: `Key components include:
Solar Panels: Convert sunlight into electricity.
Inverters: Convert direct current (DC) electricity from solar panels into alternating current (AC) for the grid.
Mounting Systems: Support and position solar panels.
Transformers: Adjust voltage levels to match grid requirements.
Grid Connection Infrastructure: Includes cables, substations, and switchgear to connect the solar plant to the grid.`,
  },
  {
    id: "faq-3",
    title:
      "What is the typical lifespan of a megawatt-scale solar power project?",
    description:
      "The typical lifespan of a solar power project is around 25-30 years. However, the efficiency of solar panels may gradually decrease over time, and regular maintenance is required to ensure optimal performance.",
  },
  {
    id: "faq-4",
    title:
      "What are the regulatory requirements for megawatt-scale solar projects?",
    description: `Regulatory requirements may include:
Environmental Clearances: Ensuring compliance with environmental regulations.
Land Use Permits: Securing permits for land use.
Grid Connection Approvals: Obtaining approvals from grid operators for connecting to the grid.
Safety and Quality Standards: Meeting technical and safety standards.`,
  },
];

export const mwSolarBenefits: Benefit[] = [
  {
    id: "01",
    title: "Project Planning",
    description:
      "We work closely with you to understand your energy needs, budget, and space constraints. We source top-quality MW solar panels, inverters, mounting structures, and other essential equipment.",
    imageUrl: "", // No image visible for this step.
  },
  {
    id: "02",
    title: "Permits and Approvals",
    description:
      "We navigate the permitting process, obtaining necessary clearances from local authorities. We assist you in exploring financing options to ensure project feasibility.",
    imageUrl: "", // Insert the actual image URL here.
  },
  {
    id: "03",
    title: "Construction and Installation",
    description:
      "Our experienced team meticulously installs the MW solar power plant.",
    imageUrl: "", // No image visible for this step.
  },
  {
    id: "04",
    title: "Grid Connection and Commissioning",
    description:
      "We facilitate the connection of your plant to the grid and ensure its optimal performance.",
    imageUrl: "", // No image visible for this step.
  },
];
