import Attributes from "./attributes/attributes.component";

import "./about-us.styles.scss";
import { Carousel } from "react-responsive-carousel";
import { CSSProperties, FC } from "react";
import { colors } from "theme/colors";
import mLogo from "assets/images/M.png";

interface Props {
  images: string[];
  title: string;
  titleHighlight: string;
  description: string;
}

const AboutUs: FC<Props> = ({ images, title, titleHighlight, description }) => {
  const indicatorStyles: CSSProperties = {
    background: colors.darkGrey,
    width: 6,
    height: 6,
    borderRadius: "3px",
    display: "inline-block",
    margin: "0 8px",
    transition: "width 0.3s ease-in-out",
  };

  return (
    <section className="au-section">
      <div className="au-row">
        <div className="au-texts">
          <p className="au-title">About Us</p>
          <h2 className="au-summary">
            {title} <span>{titleHighlight}</span>
          </h2>
          <p className="au-description">{description}</p>
          <div className="au-attributes">
            <Attributes title="50+">Team Members</Attributes>
            <Attributes title="300+">Happy Customers</Attributes>
          </div>
        </div>

        <div className="au-img">
          <div
            className="a-image"
            style={{ backgroundImage: `url(${images[0]})` }}
          >
            <div
              className="a-m-tag"
              style={{ backgroundImage: `url(${mLogo})` }}
            ></div>
          </div>
        </div>

        <div className="about-carousel">
          <Carousel
            width="335px"
            showStatus={false}
            showArrows={false}
            showThumbs={false}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              if (isSelected) {
                return (
                  <li
                    style={{ ...indicatorStyles, width: 26, borderRadius: 3 }}
                    aria-label={`Selected: ${label} ${index + 1}`}
                    title={`Selected: ${label} ${index + 1}`}
                  />
                );
              }
              return (
                <li
                  style={indicatorStyles}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  title={`${label} ${index + 1}`}
                  aria-label={`${label} ${index + 1}`}
                />
              );
            }}
          >
            {images.map((img) => (
              <div
                key={img}
                className="a-image"
                style={{ backgroundImage: `url(${img})` }}
              >
                <div
                  className="a-m-tag"
                  style={{ backgroundImage: `url(${mLogo})` }}
                ></div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
