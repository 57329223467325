import { ButtonHTMLAttributes, FC } from 'react';

import './contact-us-button.styles.scss';
import { ButtonVariant } from 'types/enums';
import classNames from 'classnames';

interface LocalProps {
  variant?: ButtonVariant;
}

type Props = LocalProps & ButtonHTMLAttributes<HTMLButtonElement>;

const ContactUsButton: FC<Props> = ({ variant, ...buttonProps }) => {
  const handleClick = () => {
    const formSection = document.getElementById('form');
    if (formSection) {
      formSection.scrollIntoView({ behavior: 'smooth', block: "end" });
    }
  };

  return (
    <button
    onClick={handleClick}
      className={classNames('cu-cta', variant || ButtonVariant.contained)}
      {...buttonProps}
    >
      Contact Us
    </button>
  );
};

export default ContactUsButton;
