import classNames from "classnames";
import { useEffect, useState } from "react";

import { blogTypes } from "constants/brand.constants";
import { BlogDetail, BlogType } from "types/models";

import BlogCard from "components/homepage/blogs/blog-card/blog-card.component";

import "./all-blogs.styles.scss";
import useBlogService from "services/blog/blog.hooks";

const AllBlogs = () => {
  const [selectedBlogType, setSelectedBlogType] = useState<BlogType>(
    blogTypes[0]
  );

  const handleBlogTypeChange = (blogType: BlogType): void => {
    setSelectedBlogType(blogType);
  };

  const { data: blogs, getLatestBlogs } = useBlogService();

  useEffect(() => {
    getLatestBlogs();
  }, []);

  return (
    <section className="alb-section">
      <div className="alb-row">
        <h2 className="alb-summary">
          Stay Updated with <span>Our Latest Insights</span>
        </h2>

        <div className="alb-chips">
          {blogTypes.map((b) => (
            <div
              onClick={() => handleBlogTypeChange(b)}
              key={b.id}
              className={classNames("alb-chip", {
                active: selectedBlogType.id === b.id,
              })}
            >
              {b.title}
            </div>
          ))}
        </div>
        <div className="alb-blogs-grid">
          {(blogs as BlogDetail[])
            ?.filter((b) => b.blogType?.id === selectedBlogType.id)
            .map((b) => (
              <BlogCard key={b.id} blog={b} showTag />
            ))}
        </div>
      </div>
    </section>
  );
};

export default AllBlogs;
