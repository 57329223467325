import CardTag from "components/shared/card-tag/card-tag.component";

import "./blog-details.styles.scss";
import { BlogDetail } from "types/models";
import { FC } from "react";
import Markdown from "react-markdown";
import moment from "moment";

interface Props {
  blog: BlogDetail;
}

const BlogDetails: FC<Props> = ({ blog }) => {
  return (
    <section className="bd-wrapper">
      <h1 className="bd-title">{blog.title}</h1>
      <div className="bd-type">
        <p className="bd-type-text">{blog.blogType?.title}</p>
        <p className="bd-separator">&#8226;</p>
        <p className="bd-created-at">{moment(blog.date).format('DD MMMM, YYYY')}</p>
      </div>
      <div
        className="bd-img"
        style={{
          backgroundImage: `url(${blog.imageUrl})`,
          backgroundSize: "cover",
        }}
      />
      <div className="bd-row">
        <div className="bd-content-column">
          <p className="bd-intro">{blog.introduction}</p>

          {blog.content.map((b, i) => (
            <div key={b.title} className="bd-content">
              <h4 className="bdc-title">
                {i + 1}. {b.title}
              </h4>
              <Markdown className="bdc-description">{b.description}</Markdown>
            </div>
          ))}

          <div className="bdc-conclusion bd-content">
            <h3 className="bdc-conc-title">Conclusion</h3>
            <p className="bdc-conclusion-text">{blog.conclusion}</p>
          </div>
        </div>

        <div className="bd-table-of-content">
          <h5 className="bd-toc-title">Table of Content</h5>
          <ul className="bd-content-list">
            <li>Introduction</li>
            {blog.content.map((b) => (
              <li key={b.title}>{b.title}</li>
            ))}
            <li>Conclusion</li>
          </ul>
        </div>
      </div>

      <div className="bd-tags">
        {blog.tags.map((t) => (
          <CardTag key={t} title={t} />
        ))}
      </div>
    </section>
  );
};

export default BlogDetails;
