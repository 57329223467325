import "./our-projects.styles.scss";
import CustomButton from "components/shared/buttons/custom-button/custom-button.component";
import ProjectCard from "components/shared/project-card/project-card.component";
import { useNavigate } from "react-router-dom";
import { appRoutes } from "constants/routes";
import useProjectService from "services/project/project.hooks";
import { useEffect } from "react";
import { BrandProject } from "types/models";

const OurProjects = () => {
  const navigate = useNavigate();

  const { getProjects, data } = useProjectService();

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <section className="op-section">
      <div className="op-content">
        <div className="op-texts">
          <h2 className="op-summary">
            Our <span>Projects</span>
          </h2>
        </div>

        <div className="op-grid">
          <div
            className="op-grid-item op-card"
            style={{
              backgroundImage: `url(https://res.cloudinary.com/dsmpun8yd/image/upload/v1727529930/IMG_5878_mnhvcx.webp)`,
              backgroundPosition:'top center',
              backgroundSize:'cover'
            }}
          />
          {(data as BrandProject[])
            ?.filter((p) => p.featured)
            .map((p) => (
              <ProjectCard project={p} key={p.id} />
            ))}
        </div>

        <div className="pt-4 w-full flex justify-center">
          <CustomButton onClick={() => navigate(`${appRoutes.projects.path}`)}>
            View all
          </CustomButton>
        </div>
      </div>
    </section>
  );
};

export default OurProjects;
