export const brandName = "Mittal Plastomet Ltd.";

export const brandPhone = "9630144250";

export const brandOffice = `Near, Kanha Dairy Farm RV117 Indus Garden 2 Bawariya, Indus Garden 2 Bawariya E8 Extension, Extol College Rd, E-8, Extension, Bhopal, Madhya Pradesh 462026`;

export const brandManufacturing = `E-82 Phase 2 New Industrial area, Satlapur, Mandideep, Bhopal, Madhya Pradesh 462046`;

export const brandAbout = `Mittal Plastomet Limited emerged onto the scene in 2015 in
            Mandideep, Madhya Pradesh, driven by a singular ambition: to deliver
            unparalleled quality to the market. Since its inception, MPL has
            undergone remarkable growth, specializing in the in-house
            manufacturing of high-quality Solar module Gi structures and SOLAr
            EPC (engineering, procurement, construction).`;

export const whyUsStrings = {
  expertise: {
    title: "Expertise",
    summary:
      "With decades of experience in solar technology and installation, we bring unparalleled knowledge & reliability to every project, ensuring top-notch service & effective energy solutions for all business types.",
  },
  quality: {
    title: "Quality",
    summary:
      "We utilise only high-performance solar panels and components, ensuring long-lasting and efficient energy solutions. Our commitment to quality guarantees maximum energy output and system durability.",
  },
  support: {
    title: "Support",
    summary:
      "Our comprehensive maintenance and customer service ensure that your solar systems run smoothly. We offer prompt support and regular maintenance to keep your systems performing optimally.",
  },
};

export const canonicalUrl = "https://mplindia.co.in";
