import {
  petPreformOfferings,
  preformData,
} from "constants/pet-preform.constants";
import "./preform-offerings.styles.scss";
import { PetPreform } from "types/models";
import { FC } from "react";

interface Props {
  preform: PetPreform;
}

const PreformOfferingCard: FC<Props> = ({ preform }) => {
  return (
    <div className="pof-card">
      <div
        className="pof-img"
        style={{
          backgroundImage: `url(${preform.imageUrl})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <p className="pof-item-name">{preform.name}</p>
    </div>
  );
};

const PreformOfferings = () => {
  return (
    <section className="pof-wrapper">
      <div className="pof-container">
        <h2 className="pof-summary">
          Our Pet Preform <span>Offerings</span>
        </h2>

        <p className="pof-description" >
          The PET preform manufacturing process involves converting PET pellets
          into hollow, mold-shaped preforms. These preforms are then heated and
          blow-molded into the final bottle or container shape. MPL India offers
          access to a diverse range of PET preforms produced in Bhopal and other
          Indian locations.
        </p>

        <div className="pof-grid">
          {petPreformOfferings.map((preform) => (
            <PreformOfferingCard preform={preform} key={preform.name} />
          ))}
        </div>

        <div className="pof-data-container">
          <table className="pof-table">
            <thead>
              <tr>
                {Object.keys(preformData).map((key, index) => (
                  <th key={index}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {preformData[Object.keys(preformData)[0]].map((_, index) => (
                <tr key={index}>
                  {preformData &&
                    Object.keys(preformData).map((key) => (
                      <td key={key}>{preformData[key][index]}</td>
                    ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default PreformOfferings;
