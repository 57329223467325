import { canonicalUrl } from "constants/strings.constants";
import { FC, ReactNode } from "react";
import { Helmet } from "react-helmet";

interface Props {
  children: ReactNode;
  route: string;
  meta?: {
    title: string;
    description: string;
  };
}

const RouteHelmet: FC<Props> = ({ route, children, meta }) => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${canonicalUrl}${route}`} />
        {meta?.title && <title>{meta.title}</title>}
        {meta?.description && (
          <meta name="description" content={meta.description} />
        )}
      </Helmet>
      {children}
    </>
  );
};

export default RouteHelmet;
