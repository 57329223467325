import { BrandProject } from "types/models";

export const projects: BrandProject[] = [
  {
    id: "01",
    title: "Residential 32kW - System",
    capacity: "32kW",
    conclusion:
      "Our 32 kW residential rooftop solar installation with Adani bifacial panels and a Polycab inverter represents a significant leap toward energy independence and sustainability. With superior components and expert installation, this system will provide the homeowner with reliable, clean energy for years to come, while also contributing to a more eco-friendly lifestyle.",
    highlights: [],
    imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728525960/Rectangle_6488_ggzfpa.webp",
    location: "Bhopal, Madhya Pradesh",
    projectFeatures: [
      {
        summary:
          "The installation of the 32 kW rooftop solar system was completed over a period of 1 month including prefabricated shed structure to support 32kW solar sytem, ensuring minimal disruption to the household. Here’s a step-by-step breakdown of the process:",
        title: "Bill of Quantities (BOQ)",
        highlights: [
          {
            description:
              "For this project, our team engineered a custom Mild Steel (MS) structure to serve as the foundation for the entire solar system. The MS structure was fabricated to provide robust support for the solar panels and ensure long-term stability. This structure not only holds the solar array but was also designed as a multipurpose shed, offering both energy generation above and functional space below.",
            title:
              "Fabricated Structure: MS Structure to Support the Entire Solar System",
          },
          {
            description:
              "These bifacial panels are designed to capture sunlight from both sides, increasing energy output and efficiency, especially in reflective environments.",
            title:
              "Solar Panels: Adani Bifacial Panels (540W, Monocrystalline)",
          },
          {
            description:
              "The Polycab inverter is known for its high efficiency, reliability, and adaptability, perfectly suited for residential solar systems.",
            title: "Inverters: Polycab Inverter (3-phase) ",
          },
          {
            description:
              "Apollo's Galvanized Iron (GI) structure was chosen for its corrosion resistance and strength, ensuring long-lasting support for the solar panels.",
            title: "Mounting Structure: Apollo GI Structure",
          },
          {
            description:
              "High-quality DC/AC cabling with proper UV protection was used to ensure the system operates efficiently with minimal transmission losses.",
            title: "Cabling & Accessories:",
          },
        ],
      },
      {
        title: "Installation Process",
        summary:
          "The installation of the 32 kW rooftop solar system was completed over a period of 1 month, ensuring minimal disruption to the household. Here’s a step-by-step breakdown of the process:",
        highlights: [
          {
            title: "Site Assessment and Design",
            description:
              "Our team carried out a thorough site evaluation, taking into account roof area, sunlight exposure, and any potential shading from trees or nearby buildings. Based on this, we designed a layout that maximized the available space and ensured optimal sunlight absorption.",
          },
          {
            title: "Mounting Structure Setup",
            description:
              "The Apollo GI structure was carefully assembled and installed. Our engineers ensured the mounting was firmly anchored to the roof, providing a stable base to withstand wind and weather conditions, while maintaining the correct tilt angle for optimal energy absorption.",
          },
          {
            title: "Solar Panel Installation",
            description:
              "A total of 60 Adani panels were securely placed on the mounting structure. The panels were aligned to capture the maximum amount of sunlight throughout the day, reducing shading from nearby obstacles.",
          },
          {
            title: "Inverter and Cabling",
            description:
              "We installed Polycab 3-phase inverter in the home's electrical system. This inverter efficiently converts the DC energy generated by the panels into usable AC power for the household, ensuring seamless energy distribution.",
          },
          {
            title: "System Testing and Commissioning",
            description:
              "After the physical installation, our team ran multiple tests to ensure the system's efficiency, safety, and stability. This included checking the wiring, inverter performance, and conducting a full-scale operational test before commissioning.",
          },
        ],
      },
      {
        title: "Significant Benefits",
        summary:
          "This 32 kW residential solar installation offers significant benefits that will enhance the homeowner’s energy independence and cost savings. Here are the primary advantages:",
        highlights: [
          {
            title: "Significant Energy Savings",
            description:
              "With a 32 kW capacity, the household will be able to cover a large portion, if not all, of its electricity needs through solar energy. This results in 80% reduction in monthly energy bills, providing immediate financial relief and long-term savings.",
          },
          {
            title: "Increased Energy Efficiency with Bifacial Panels",
            description:
              "Adani bifacial panels are designed to harness both direct and reflected sunlight, boosting overall system efficiency by up to [insert percentage] compared to traditional panels. This ensures more energy production even in less-than-ideal lighting conditions.",
          },
          {
            title: "Enhanced Property Value",
            description:
              "Solar installations are known to increase property value. With a system of this size and the use of high-quality bifacial panels, the home becomes more attractive to potential buyers who value energy efficiency and sustainability.",
          },
        ],
      },
    ],
    summary: [],
  },

  {
    id: "02",
    title: "Industrial 200kW - Mandideep",
    capacity: "100kW",
    conclusion:
      "Our 200 kW rooftop solar installation demonstrates a commitment to harnessing renewable energy for industrial use. This project not only lowers operating costs but also positions the facility as a forward-thinking leader in sustainable practices. The integration of high-quality components and expert installation ensures that this system will provide reliable, clean energy for decades to come.",
    highlights: [
      "Waree Panels",
      "Solice inverter ",
      "GI Apollo structure custom Made in Mittal Plastomet Limited",
    ],
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1722830064/IMG_5753_alyv4z.jpg",
    location: "Mandideep, Madhya Pradesh",
    projectFeatures: [
      {
        summary:
          "Our recent 200 kW rooftop solar installation for an industrial facility is designed to provide high-efficiency, long-term energy savings. Below is the detailed BOQ for the equipment used:",
        title: "Bill of Quantities (BOQ)",
        highlights: [
          {
            description:
              "These panels are renowned for their durability and high performance, ensuring consistent energy generation even in varying sunlight conditions.",
            title:
              "Solar Panels: Warree Panels (540W, Mono PERC Bifacial NoN DCR)",
          },
          {
            description:
              "The Solis inverter, known for its reliability, is used to convert DC energy from the panels into AC energy for industrial use.",
            title: "Inverters: Solis Inverter (3-phase)",
          },
          {
            description:
              "Apollo's Galvanized Iron (GI) structure was chosen for its corrosion resistance and strength, ensuring long-lasting support for the solar panels.",
            title: "Mounting Structure: Apollo GI Structure",
          },
          {
            description:
              "High-quality DC/AC cabling with proper UV protection was used to ensure the system operates efficiently with minimal transmission losses.",
            title: "Cabling & Accessories:",
          },
        ],
      },
      {
        title: "Installation Process",
        summary:
          "The installation of the 200 kW rooftop solar system was completed over a period of 2 months, and involved the following key steps:",
        highlights: [
          {
            title: "Site Assessment and Design",
            description:
              "Our team conducted a comprehensive assessment of the industrial facility’s roof, analyzing the structural integrity, roof space, and sunlight exposure. We designed a customized layout to maximize energy production while ensuring minimal impact on existing infrastructure.",
          },
          {
            title: "Mounting Structure Setup",
            description:
              "The Apollo GI structure was carefully assembled and installed. Our engineers ensured the mounting was firmly anchored to the roof, providing a stable base to withstand wind and weather conditions, while maintaining the correct tilt angle for optimal energy absorption.",
          },
          {
            title: "Solar Panel Installation",
            description:
              "A total of 371 Warree panels were securely placed on the mounting structure. The panels were aligned to capture the maximum amount of sunlight throughout the day, reducing shading from nearby obstacles.",
          },
          {
            title: "Inverter and Cabling",
            description:
              "We installed two Solis 3-phase inverter 100kW each in a central location, optimizing the energy flow between the panels and the facility's electrical system. DC and AC cabling were laid with protective conduits to prevent wear and tear over time.",
          },
          {
            title: "System Testing and Commissioning",
            description:
              "After the physical installation, our team ran multiple tests to ensure the system's efficiency, safety, and stability. This included checking the wiring, inverter performance, and conducting a full-scale operational test before commissioning.",
          },
        ],
      },
      {
        title: "Industry Benefits of this Installation",
        summary:
          "This 200 kW installation is not just an investment in renewable energy but a step toward greater operational efficiency for the industrial facility. Here are the key benefits this project will bring:",
        highlights: [
          {
            title: "Reduction in Energy Costs",
            description:
              "The facility is now able to generate its own electricity, reducing reliance on the grid. This will result in an estimated 50% savings on monthly energy bills, contributing to significant cost savings over time.",
          },
          {
            title: "Improved Energy Security Improved Energy Security",
            description:
              "By generating renewable energy onsite, the facility has improved energy resilience, ensuring stable power supply even during grid disruptions or price fluctuations.",
          },
          {
            title: "Reduction in Carbon Footprint",
            description:
              "With the combination of high-efficiency Warree panels and Solis inverters, the system is designed to provide a fast return on investment (ROI), with a payback period of approximately 4 years.",
          },
          {
            title: "Faster ROI",
            description:
              "We installed two Solis 3-phase inverter 100kW each in a central location, optimizing the energy flow between the panels and the facility's electrical system. DC and AC cabling were laid with protective conduits to prevent wear and tear over time.",
          },
          {
            title: "Compliance with Renewable Energy Standards",
            description:
              "The project aligns with industry regulations and supports the government’s push for more industrial facilities to adopt renewable energy, ensuring compliance and opening doors for future incentives.",
          },
        ],
      },
    ],
    summary: [
      "To reduce electricity costs and enhance sustainability by installing a 200 kW solar rooftop system on an industrial facility in Mandideep.",
      "The project involved assessing the facility’s roof, designing the solar power system, and completing the installation. The system was designed to generate a significant portion of the facility’s energy needs, aiming for long-term cost savings and environmental benefits.",
    ],
    featured: true,
  },
  {
    id: "3",
    title: "77 kW Industrial Solar Rooftop, Mandideep",
    highlights: [
      "Waree Panels",
      "Solice inverter ",
      "GI Apollo structure custom Made in Mittal Plastomet Limited",
    ],
    summary: [
      "The 77 kW system generates around 110,000 kWh of clean energy annually, helping to lower the facility’s carbon emissions by roughly 80 tons of CO2 per year.",
      "The facility benefits from increased energy independence and reliability. The solar power system’s performance is monitored regularly, and maintenance needs are minimal, contributing to overall operational efficiency.",
    ],
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1722830063/IMG_5754_tk8iqb.jpg",
    capacity: "77 kw",
    conclusion: "",
    location: "Mandideep, Madhya Pradesh",
    projectFeatures: [],
    featured: true,
  },
];
