import { ToastContainer } from "react-toastify";
import Header from "components/layout/header/header.component";
import { Route, Routes } from "react-router-dom";

import { appRoutes } from "constants/routes";
import { toastContainerConfig } from "utils/toast.utils";

import ThankYou from "pages/thank-you.page";
import Footer from "components/layout/footer/footer.component";
import Homepage from "pages/home.page";
import Commercial from "pages/solutions/commercial.page";
import Residential from "pages/solutions/residential.page";
import SideNav from "components/layout/side-nav/side-nav.component";
import Blog from "pages/blog.page";
import HousingSocieties from "pages/solutions/housing-societies.page";
import BlogDetailsPage from "pages/blog-details.page";
import MegawattProjects from "pages/solutions/megawatt.page";
import SolarPanelStructure from "pages/manufacture/solar-panel-structure.page";
import RouteHelmet from "components/hoc/route-helmet.component";
import FencingWire from "pages/manufacture/fencing-wire.page";
import PetPreform from "pages/manufacture/pet-preform.page";
import About from "pages/about.page";
import Projects from "pages/projects";
import ProjectDetailsPage from "pages/project-details.page";

import "./App.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "components/scroll-to-top/sroll-to-top.component";

function App() {
  return (
    <div className="app-wrapper">
      <ScrollToTop />
      <div className="page-container">
        <Header />
        <SideNav />
        <Routes>
          <Route
            path={appRoutes.thankYou.path}
            element={
              <RouteHelmet route={appRoutes.thankYou.path}>
                <ThankYou />
              </RouteHelmet>
            }
          />
          <Route
            path={appRoutes.home.path}
            element={
              <RouteHelmet
                meta={appRoutes.home.meta}
                route={appRoutes.home.path}
              >
                <Homepage />
              </RouteHelmet>
            }
          />
          <Route
            path={appRoutes.commercial.path}
            element={
              <RouteHelmet
                route={appRoutes.commercial.path}
                meta={appRoutes.commercial.meta}
              >
                <Commercial />
              </RouteHelmet>
            }
          />
          <Route
            path={appRoutes.residential.path}
            element={
              <RouteHelmet
                meta={appRoutes.residential.meta}
                route={appRoutes.residential.path}
              >
                <Residential />
              </RouteHelmet>
            }
          />
          <Route
            path={appRoutes.megawatt.path}
            element={
              <RouteHelmet
                meta={appRoutes.megawatt.meta}
                route={appRoutes.megawatt.path}
              >
                <MegawattProjects />
              </RouteHelmet>
            }
          />
          <Route
            path={`${appRoutes.blog.path}/:blogId`}
            element={<BlogDetailsPage />}
          />
          <Route
            path={appRoutes.blog.path}
            element={
              <RouteHelmet
                meta={appRoutes.blog.meta}
                route={appRoutes.blog.path}
              >
                <Blog />
              </RouteHelmet>
            }
          />
          <Route
            path={appRoutes.housingSocieties.path}
            element={
              <RouteHelmet
                meta={appRoutes.housingSocieties.meta}
                route={appRoutes.housingSocieties.path}
              >
                <HousingSocieties />
              </RouteHelmet>
            }
          />
          <Route
            path={appRoutes.solarPanelsStructure.path}
            element={
              <RouteHelmet
                meta={appRoutes.solarPanelsStructure.meta}
                route={appRoutes.solarPanelsStructure.path}
              >
                <SolarPanelStructure />
              </RouteHelmet>
            }
          />
          <Route
            path={appRoutes.fencingWires.path}
            element={
              <RouteHelmet
                meta={appRoutes.fencingWires.meta}
                route={appRoutes.fencingWires.path}
              >
                <FencingWire />
              </RouteHelmet>
            }
          />
          <Route
            path={appRoutes.petPreform.path}
            element={
              <RouteHelmet
                route={appRoutes.petPreform.path}
                meta={appRoutes.petPreform.meta}
              >
                <PetPreform />
              </RouteHelmet>
            }
          />
          <Route
            path={appRoutes.about.path}
            element={
              <RouteHelmet
                route={appRoutes.about.path}
                meta={appRoutes.about.meta}
              >
                <About />
              </RouteHelmet>
            }
          />
          <Route
            path={`${appRoutes.projects.path}/:projectId`}
            element={<ProjectDetailsPage />}
          />
          <Route path={appRoutes.projects.path} element={<Projects />} />
        </Routes>
      </div>
      <div className="footer">
        <Footer />
      </div>
      <ToastContainer {...toastContainerConfig} />
    </div>
  );
}

export default App;
