import { defaultServiceState } from "constants/api.constants";
import { useState } from "react";
import { ServiceState } from "types/api.types";
import { BlogDetail } from "types/models";
import { fetchBlogById, fetchBlogs, fetchLatestBlogs } from "./blog.services";

const useBlogService = () => {
  const [project, setProject] =
    useState<ServiceState<BlogDetail[] | BlogDetail>>(defaultServiceState);

  const getBlogs = () => {
    try {
      setProject((prev) => ({ ...prev, loading: true }));
      const res = fetchBlogs();
      setProject((prev) => ({ ...prev, data: res }));
    } catch (error) {
      setProject((prev) => ({
        ...prev,
        data: undefined,
        error: { message: "Something went wrong", status: 500 },
      }));
    } finally {
      setProject((prev) => ({ ...prev, loading: false }));
    }
  };

  const getBlogById = (id: string) => {
    try {
      setProject((prev) => ({ ...prev, loading: true }));
      const res = fetchBlogById(id);
      setProject((prev) => ({ ...prev, data: res }));
    } catch (error) {
      setProject((prev) => ({
        ...prev,
        data: undefined,
        error: { message: "Something went wrong", status: 500 },
      }));
    } finally {
      setProject((prev) => ({ ...prev, loading: false }));
    }
  };
  const getLatestBlogs = () => {
    try {
      setProject((prev) => ({ ...prev, loading: true }));
      const res = fetchLatestBlogs();
      setProject((prev) => ({ ...prev, data: res }));
    } catch (error) {
      setProject((prev) => ({
        ...prev,
        data: undefined,
        error: { message: "Something went wrong", status: 500 },
      }));
    } finally {
      setProject((prev) => ({ ...prev, loading: false }));
    }
  };

  return {
    getBlogs,
    getBlogById,
    getLatestBlogs,
    data: project.data,
    loading: project.loading,
    error: project.error,
  };
};

export default useBlogService;
