import { GoTag, GoThumbsup } from "react-icons/go";
import { IoIosStarOutline } from "react-icons/io";
import { Benefit, BrandFaq, BrandWhyUs, Offering } from "types/models";
import { ServiceBenefit } from "types/services.types";

export const residentialFAQS: BrandFaq[] = [
  {
    id: "faq-1",
    title: "What is the average cost of installing solar panels for a home?",
    description:
      "The solar panel for home price can vary based on factors like system size and installation specifics. For a personalized quote, contact us to get detailed information on solar panels for home cost.",
  },
  {
    id: "faq-2",
    title: "What are the benefits of a rooftop solar system?",
    description:
      "A rooftop solar system can significantly reduce your electricity bills, offer energy independence, and contribute to a greener environment. It’s a smart investment in solar for home energy solutions.",
  },
  {
    id: "faq-3",
    title:
      "How does the Pradhan Mantri Suryoday Yojana support solar adoption?",
    description:
      "The Pradhan Mantri Suryoday Yojana is a government initiative aimed at promoting residential solar energy by providing financial incentives and support for solar panel installations. This scheme helps reduce the overall solar panel for home price in India.",
  },
  {
    id: "faq-4",
    title:
      "How do I choose between grid tie, off-grid, and hybrid solar systems?",
    description:
      "Your choice depends on your energy needs and goals. Grid tie systems are connected to the power grid, off-grid systems offer complete independence, and hybrid systems combine both for flexibility. Our consultants can help you decide which solar system for your home is best.",
  },
  {
    id: "faq-5",
    title:
      "Where can I find a reliable rooftop solar system company in Madhya Pradesh?",
    description:
      "Look for companies with proven expertise and positive reviews. We are a leading rooftop solar system company in Madhya Pradesh, known for delivering high-quality solar panels and excellent customer service.",
  },
];

export const residentialWhyUsStrings: BrandWhyUs[] = [
  {
    title: "Expertise",
    icon: <IoIosStarOutline />,
    description:
      "Our specialization in rooftop solar systems ensures you get a solution tailored to your specific needs. As a leading rooftop solar system company in Madhya Pradesh, we leverage our extensive experience to design and install systems that maximize efficiency and performance. Trust in our expertise to provide you with the best solar panels on roof for optimal energy savings.",
  },
  {
    title: "Quality",
    icon: <GoThumbsup />,
    description:
      "We are committed to delivering superior results and comprehensive support throughout your solar journey. From expert installation to ongoing maintenance, our team ensures that every aspect of your solar system for home is handled with care and professionalism. Our rigorous quality standards and dedication to customer satisfaction set us apart as the preferred choice for residential solar panels.",
  },
  {
    title: "Competitive Pricing on Solar Panels for Home",
    icon: <GoTag />,
    description:
      "We provide competitive solar panels for home cost, ensuring you receive excellent value for your investment. Our pricing is transparent, helping you understand the full scope of your expenditure without hidden fees. This affordability, combined with our high-quality products, makes us a top choice for homeowners looking for solar systems for homes.",
  },
];

export const residentialBenefits: ServiceBenefit[] = [
  {
    title: "Custom Solar Installations for Homes",
    description: `Tailored solar panel installations maximize energy efficiency, seamlessly integrating with your home's architecture for a sustainable, cost-effective solution.`,
  },
  {
    title: "Energy Audits and Consultation",
    description:
      "Comprehensive energy audits identify solar integration opportunities, providing expert consultation for optimal energy savings and sustainability.",
  },
  {
    title: "Maintenance and Support Services",
    description:
      "Ongoing maintenance ensures peak performance with regular inspections, upgrades, and prompt troubleshooting for smooth operation.",
  },
  {
    title: "Financing and Incentive Guidance",
    description:
      "Navigate financing options and government incentives to access rebates, tax credits, and maximize your return on investment.",
  },
];

export const residentialSolarBenefits: Benefit[] = [
  {
    id: "1",
    title: "Cost Savings",
    description:
      "By generating your own electricity, you can significantly reduce or even eliminate your electricity bills.",
    imageUrl: "",
  },

  {
    id: "2",
    title: "Environmentally Friendly",
    description:
      "Solar energy is a renewable resource, meaning it reduces your home's carbon footprint.",
    imageUrl: "",
  },

  {
    id: "3",
    title: "Increased Property Value",
    description:
      "Homes equipped with residential solar panels often sell at higher prices and faster than those without.",
    imageUrl: "",
  },

  {
    id: "4",
    title: "Energy Independence",
    description:
      "Installing a solar rooftop system gives you greater control over your energy supply, reducing dependence on traditional power grids",
    imageUrl: "",
  },
];

export const residentialOfferings: Offering[] = [
  {
    id: "1",
    title: "Solar Panels for Home",
    description:
      "These panels, mounted on your roof, are the most critical part of the system as they capture sunlight and convert it into electricity.",
  },
  {
    id: "4",
    title: "Mounting Structures",
    description:
      "These hold the panels in place on your roof and are designed to withstand various weather conditions.",
  },
  {
    id: "2",
    title: "Inverters",
    description: `These devices transform the DC electricity produced by the solar panels into AC electricity used by most home appliances.`,
  },
  {
    id: "3",
    title: "Batteries",
    description:
      "If you want to store excess energy for use during cloudy days or at night, you can opt for solar batteries.",
  },
];
