import {
  Benefit,
  BlogDetail,
  BlogType,
  BrandCertificate,
  BrandFaq,
  BrandManufacture,
  BrandSolution,
  Offering,
  SolarSolution,
  Testimonial
} from "types/models";
import { appRoutes } from "./routes";

export const brandAboutImages: string[] = [
  "https://res.cloudinary.com/dsmpun8yd/image/upload/v1722829563/image_rofddr.png",
  "https://res.cloudinary.com/dsmpun8yd/image/upload/v1722829941/IMG_5760_dgi5pl.jpg",
  "https://res.cloudinary.com/dsmpun8yd/image/upload/v1722829940/IMG_5757_uamd7g.jpg",
  "https://res.cloudinary.com/dsmpun8yd/image/upload/v1722829939/IMG_5761_rtapda.jpg",
];

export const brandSolutions: BrandSolution[] = [
  {
    id: "residential",
    title: "Residential",
    description:
      "Harness solar energy for your home, reducing costs and promoting sustainability with easy installation and low maintenance.",
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727516452/Residential_rooftop_r30ic6.webp",
    route: appRoutes.residential.path,
  },
  {
    id: "commercial",
    title: "Commercial",
    description:
      "Empower your business with scalable solar solutions, cutting operational costs and enhancing sustainability.",
    route: appRoutes.commercial.path,
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727515585/IMG_5873_1_udul63.webp",
  },
  {
    id: "housingSocieties",
    title: "⁠Housing Societies",
    description:
      "Provide collective energy benefits with shared solar systems, lowering electricity bills for communities.",
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1722790796/Rectangle_j4bwpy.png",
    route: appRoutes.housingSocieties.path,
  },
  {
    id: "outdoorSpaces",
    title: "Outdoor Spaces",
    description:
      "Power parks and public areas sustainably with durable solar systems for reliable outdoor lighting.",
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1722790792/powr_parks_netnep.png",
  },
  {
    id: "megawattProjects",
    route: appRoutes.megawatt.path,
    title: "Megawatt Projects",
    description:
      "Deliver high-capacity solar solutions for utilities and large enterprises, driving significant energy production and sustainability.",
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727517163/pixelcut-export_f6suee.webp",
  },
];

export const brandManufactures: BrandManufacture[] = [
  {
    id: "solarPanels",
    title: "Solar Panel Structure",
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/e_improve/v1727518696/IMG_1825_ipq1ta.webp",
    route: appRoutes.solarPanelsStructure.path,
  },
  {
    id: "fencing",
    title: "Fencing Wires",
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727518887/IMG_1819_uafjov.webp",
    route: appRoutes.fencingWires.path,
  },
  {
    id: "petPreForm",
    title: "PET Preform",
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1722790873/bcf845dff3028870ae1bae7a23723f44_mpryvu.png",
    route: appRoutes.petPreform.path,
  },
];

export const testimonials: Testimonial[] = [
  {
    id: "1",
    clientName: "Rakesh Jangalwa",
    testimonial:
      "Best solar energy solutions in the city of Bhopal! They are very skilled and helpful with all your needs. Highly recommend!",
    clientDesignation: "Finance Professional",
    clientImageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727525704/pixelcut-export_1_a9isoo.webp",
  },
  {
    id: "2",
    clientName: "Poonam Dhauka",
    testimonial: `One stop solution for all the solar installation services.
    Highly trained and skilled staff, quick service and no reminders for monthly service check.
    Value for money.
    Best in bhopal. 10/10 !
    `,
    clientDesignation: "Influencer",
    clientImageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727526040/pixelcut-export_2_uvlsdz.webp",
  },
  {
    id: "3",
    clientName: "Jay Singh",
    testimonial:
      "Great services and amazing products. The team is very responsive and takes care of the requirements of clients",
    clientDesignation: "Gym Owner",
    clientImageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727526252/IMG_6929_z9abvz.webp",
  },
];

export const brandCertificates: BrandCertificate[] = [
  {
    id: "0",
    certificate: "STRUCTURE",
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1722791066/apollo_diw8so.png",
  },
  {
    id: "1",
    certificate: "BIS",
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1722791066/bis_di3ury.png",
  },
  {
    id: "2",
    certificate: "ISI",
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1722791069/isi_ohlunl.png",
  },
  {
    id: "3",
    certificate: "MSME",
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1722791070/msme_zlxidc.png",
  },
  {
    id: "4",
    certificate: "MPMKVVCL",
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1722791073/mpm_ugmqis.png",
  },
];

export const brandFaqs: BrandFaq[] = [
  {
    id: "faq-1",
    title: "What are the benefits of solar energy?",
    description:
      "Solar energy reduces electricity bills, increases property value, and promotes environmental sustainability by reducing carbon emissions. Solar panels can also work during cloudy or rainy days, although their efficiency might be slightly reduced.",
  },
  {
    id: "faq-2",
    title: "How long does it take to install a solar system?",
    description:
      "The installation time for a solar system depends on the size of the system and the complexity of the installation process. Generally, it takes between 1 to 3 days for residential installations and up to a few weeks for larger commercial or utility-scale projects.",
  },
  {
    id: "faq-3",
    title: "How much can I save with solar panels?",
    description:
      "The savings from solar panels depend on various factors, including your energy consumption, the size of the system, and local electricity rates. On average, homeowners can save around 40% to 70% on their electricity bills, while businesses can achieve even higher savings.",
  },
  {
    id: "faq-4",
    title: "Are there any maintenance requirements for solar panels?",
    description:
      "Solar panels require minimal maintenance, usually limited to keeping them clean and free of debris. Most solar systems come with monitoring systems that allow you to track their performance and identify any issues proactively.",
  },
  {
    id: "faq-5",
    title: "Can solar panels work during cloudy or rainy days?",
    description:
      "Yes, solar panels can still generate electricity during cloudy or rainy days, although their output will be lower compared to sunny days. Modern solar panels are designed to maximize their efficiency even in low-light conditions.",
  },
  {
    id: "faq-6",
    title: "What is the lifespan of a solar panel system?",
    description:
      "Solar panel systems typically have a lifespan of 25 to 30 years, during which they can maintain around 80% to 90% of their initial efficiency. After this period, their efficiency may decline further, but many solar panels continue to produce electricity for many more years.",
  },
];

export const blogTypes: BlogType[] = [
  { id: "residential-solar", title: "Residential Solar" },
  // { id: "commercial-solar", title: "Commercial Solar" },
  { id: "solar-tech", title: "Solar Technology" },
  // { id: "sustainability", title: "Sustainability" },
  { id: "industry-news", title: "Industry News" },
  // { id: "government-policies", title: "Government Policies" },
];

export const rooftopSolarSolution: SolarSolution[] = [
  {
    id: "blog-1",
    title: "Grid-Tie Solar Systems",
    details: [
      "Connected to the public electricity grid.",
      "Use solar power during the day.",
      "Draw power from the grid when solar production is low.",
      "Excess energy can be fed back into the grid.",
      "Potentially earn credits or reduce your electricity bill.",
    ],
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727524136/grid-tied-solar-system_q5rigj.webp",
  },
  {
    id: "blog-2",
    title: "Off-Grid Solar Systems",
    details: [
      "Operate independently of the public electricity grid.",
      "Ideal for remote or rural areas.",
      "Include battery storage for energy use when sunlight is unavailable.",
      "Provide a continuous power supply.",
    ],
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727524137/off-grid-solar-system_buxa5m.webp",
  },
  {
    id: "blog-3",
    title: "Hybrid Solar Systems",
    details: [
      "Combine grid-tie and off-grid features.",
      "Connected to the grid with battery storage.",
      "Store excess solar energy for later use.",
      "Option to draw power from the grid when needed.",
      "Offer flexibility and reliability.",
    ],
    imageUrl:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727524136/hybrid-solar-system_kqfqul.webp",
  },
];

export const brandOfferings: Offering[] = [
  {
    id: "1",
    title: "Customised Installations",
    description:
      "We offer tailored solar panel installations designed to maximize energy efficiency and meet specific business requirements. Our custom solutions ensure optimal performance and integration with your existing infrastructure.",
  },
  {
    id: "2",
    title: "Energy Audits and Consultation",
    description: `Our comprehensive energy audits identify opportunities for solar integration. We provide expert consultation on the most effective and efficient solar solutions tailored to your business's unique energy needs.`,
  },
  {
    id: "3",
    title: "Maintenance and Support",
    description:
      "We offer ongoing maintenance and support services to ensure your solar systems operate at peak performance. Our proactive approach includes regular inspections, system upgrades, and prompt troubleshooting.",
  },
  {
    id: "4",
    title: "Financing and Incentive Guidance",
    description:
      "We assist with navigating financing options and government incentives to make solar investments more affordable. Our guidance helps you access rebates, tax credits, and other financial benefits to maximize your return on investment.",
  },
];

export const solarBenefits: Benefit[] = [
  {
    id: "1",
    title: "Cost Savings",
    description:
      "Solar panels significantly reduce energy bills by generating electricity on-site, leading to lower operational costs. Businesses can achieve substantial savings over time, making solar energy a cost-effective investment.",
    imageUrl: "",
  },

  {
    id: "2",
    title: "Sustainability",
    description:
      "Implementing solar panels lowers your carbon footprint, contributing to environmental preservation. It enhances corporate social responsibility by demonstrating a commitment to sustainable practices and reducing reliance on fossil fuels.",
    imageUrl: "",
  },

  {
    id: "3",
    title: "Reliability",
    description:
      "Solar panels provide a dependable power supply with minimal maintenance. They offer consistent energy production, reducing the risk of power outages and ensuring uninterrupted business operations.",
    imageUrl: "",
  },

  {
    id: "4",
    title: "Incentives",
    description:
      "Businesses can access various government rebates and incentives for solar installations. These financial benefits make solar investments more affordable, enhancing the return on investment through tax credits and subsidies.",
    imageUrl: "",
  },
];

export const monthlyBillRange = [
  { value: "Less than 1500" },
  { value: "₹1500 - ₹3000" },
  { value: "₹3000 - ₹5000" },
  { value: "₹5000 - ₹8000" },
  { value: "Above ₹8000" },
];
