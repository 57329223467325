import Board from "components/about/board/board.component";
import Ceo from "components/about/ceo/ceo.component";
import EndlessGrowth from "components/about/endless-growth/endless-growth.component";
import Journey from "components/about/journey/journey.component";
import MemorableMoments from "components/about/moments/moments.component";
import Team from "components/about/team/team.component";
import Certificates from "components/commercial/certificates/certificates.component";
import Form from "components/commercial/form/form.component";
import WhyUs from "components/commercial/why‐us/why‐us.component";
import AboutUs from "components/homepage/about-us/about-us.component";
import Hero from "components/shared/hero/hero.component";
import { aboutWhyUs } from "constants/about-us.constants";

const About = () => {
  return (
    <>
      <Hero
        titlePart1="About"
        titleHighlight="Us"
        backgroundUrl="https://res.cloudinary.com/dsmpun8yd/image/upload/v1728306151/Rectangle_7_1_djwqua.webp"
      />
      <AboutUs
        title="Leading the"
        titleHighlight="Way in Manufacturing"
        description="Mittal Plastomet Limited emerged onto the scene in 2015 in Mandideep, Madhya Pradesh, driven by a singular ambition: to deliver unparalleled quality to the market. Since its inception, MPL has undergone remarkable growth, specializing in the in-house manufacturing of high-quality Solar module Gi structures and Solar EPC (engineering, procurement, construction). Pet Preform, Manufacturing, Fencing Wire Manufacturing"
        images={[
          "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727524886/IMG_5873_1_udul63_rqsxdr.webp",
        ]}
      />
      <EndlessGrowth />
      <WhyUs title="Why" titleHighlight="Us?" whyUsDetails={aboutWhyUs} />
      <Certificates />
      <Journey />
      <Board />
      <Ceo />
      <Team />
      <MemorableMoments />
      <Form />
    </>
  );
};

export default About;
