import { FC } from "react";

import mLogo from "assets/images/M.png";

import "./about.styles.scss";
import classNames from "classnames";
import Markdown from "react-markdown";

interface Props {
  title: string;
  titleSize?: "small" | "regular";
  titleHighlight: string;
  summary: string;
  image?: string;
  flip?: boolean;
  imagePosition?: string;
  backgroundColor?: string;
}

const About: FC<Props> = ({
  title,
  titleHighlight,
  summary,
  image = "",
  flip,
  backgroundColor,
  imagePosition,
  titleSize = "regular",
}) => {
  return (
    <section className="ca-section" style={{ backgroundColor }}>
      <div className={classNames("ca-row", { flip })}>
        <div className="ca-texts">
          <h2 className={classNames("ca-summary", titleSize)}>
            {title} <span>{titleHighlight}</span>
          </h2>
          <Markdown className="ca-description">{summary}</Markdown>
        </div>

        <div className="ca-img">
          <div
            className="ca-image"
            style={{
              backgroundImage: `url(${image})`,
              backgroundPosition: imagePosition || "center",
              backgroundSize: 'cover'
            }}
          >
            <div
              className="ca-m-tag"
              style={{ backgroundImage: `url(${mLogo})` }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
