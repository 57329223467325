import { PersonDetails } from "../team/team.component";
import "./board.styles.scss";

const Board = () => {
  return (
    <section className="board-wrapper">
      <div className="board-row">
        <h3 className="board-title">
          Board of <span>Directors</span>
        </h3>
        <div className="board">
          <PersonDetails
            className="small-image"
            details={{
              imageUrl:
                "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728315304/e02cc4f7-fb3d-4baf-8b21-1da05000e74f_gejjoh.webp",
              name: "Seema Mittal",
              role: "",
              linkedin: "",
            }}
          />{" "}
          <PersonDetails
            className="small-image"
            details={{
              imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728836260/Screenshot_2024-10-13_at_9.47.08_PM_opzooe.png",
              name: "Rekha Mittal",
              role: "",
              linkedin: "",
            }}
          />{" "}
          <PersonDetails
            className="small-image"
            details={{
              imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727525704/pixelcut-export_1_a9isoo.webp",
              name: "Rakesh Jangalwa",
              role: "",
              linkedin: "",
            }}
          />
          <PersonDetails
            className="small-image"
            details={{
              imageUrl:
                "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728836118/WhatsApp_Image_2024-10-13_at_21.42.30_lm3f4w.jpg",
              name: "Girish Chander Dalakoti",
              role: "",
              linkedin: "",
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default Board;
