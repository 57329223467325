import { Benefit, BrandFaq, FencingWire } from "types/models";

export const fencingWires: FencingWire[] = [
  {
    name: "Barbed Wire",
    description:
      "Ideal for creating a strong deterrent against unwanted access, available in single or double-strand options.",
    details: [
      "Size - From 2.00 mm to 2.50 mm",
      "Weight - Available in 15kg & 25 Kg",
    ],
    images: [
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727837994/image_1_crtvmx.png",
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727838077/Frame_1597882273_vdkcbm.png",
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728295399/IMG_1821_swjsxj.webp",
    ],
    data: {
      "Wire Size": [
        "12 x 12  Gauge (2.50mm)",
        "13 x 13  Gauge (2.24mm)",
        "14 x 14  Gauge (2.00mm)",
      ],
      Gauge: ["22 feet", "29 feet", "32 feet"],
    },
  },
  {
    name: "Chain Link",
    description:
      "This is the most common type of fencing wire, coated with a zinc layer for superior corrosion resistance and extended lifespan.",
    details: ["Height & Length - Custom height & length available"],
    images: [
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727837993/image_2_neeoor.png",
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727838079/Frame_1597882274_lipykq.png",
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728295365/IMG_1819_qgyf9l.webp",
    ],
    data: {
      "Opening Size (in inches)": [
        "2.00 × 2.00 ",
        "2.25 × 2.25 ",
        "2.50 × 2.50 ",
        "3.00 × 3.00 ",
        "4.00 × 4.00 ",
        "5.00 × 5.00 ",
      ],
      "12 Gauge (2.5mm)": ["180", "160", "135", "110", "93", "83"],
      "11 Gauge (3.0mm)": ["240", "215", "180", "165", "120", "110"],
      "8 Gauge (4.0mm)": ["425", "380", "320", "300", "220", "200"],
    },
  },
  {
    name: "Binding Wire",
    description:
      "Used for securing fence posts and panels, offering a reliable and cost-effective solution.",
    details: [
      "Size - 20 Gauge (0.90 mm), 18 Gauge (1.22 mm)",
      "Weight - Available in 25kg & 5 Kg",
    ],
    images: [
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727837273/image_4_gougae.png",
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728295365/IMG_7400_sggiul.webp",
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727838079/Frame_1597882275_inofx3.png",
    ],
  },
  {
    name: "Welded Wiremesh",
    description:
      "This pre-welded wire mesh provides a rigid and secure fencing option, ideal for various applications.",
    details: [
      "Material - HB & GI",
      "Height & Length - Custom height & length available",
    ],
    images: [
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727837308/image_3_exmrsg.png",
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727838081/Frame_1597882276_x8luxy.png",
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728295365/IMG_8114_yiwof2.webp",
    ],
    data: {
      "Opening Size (in inches)": [
        "1.00 × 1.00 ",
        "1.25 × 1.25 ",
        "1.50 × 1.50 ",
        "2.00 × 2.00 ",
        "3.00 × 3.00 ",
        "4.00 × 4.00 ",
      ],
      "14 Gauge (2.0mm)": ["183", "143", "123", "93", "63", "46"],
      "13 Gauge (2.25mm)": ["233", "183", "156", "116", "80", "60"],
      "12 Gauge (2.5mm)": ["286", "223", "193", "143", "96", "73"],
      "11 Gauge (3.0mm)": ["346", "273", "233", "176", "120", "86"],
    },
  },
];

export const fencingBenefits: Benefit[] = [
  {
    id: "0",
    title: "Quality Assurance",
    imageUrl: "",
    description:
      "Manufacturers known for their commitment to high-quality materials and production processes.",
  },
  {
    id: "1",
    title: "Expert Guidance",
    imageUrl: "",
    description:
      "Our team can assist you in selecting the most suitable fencing wire for your specific project.",
  },
  {
    id: "2",
    title: "Cost-Effectiveness",
    imageUrl: "",
    description:
      "We strive to connect you with competitive fencing wire suppliers to meet your budget requirements",
  },
];

export const fencingFaqs: BrandFaq[] = [
  {
    id: "1",
    title: "What types of fencing wire do you manufacture?",
    description:
      "We offer a variety of fencing wire products, including:\n* • Barbed wire\n* • Welded wire fencing\n* • Chain link wire\n* • Field fencing\n* • Specialty wires for agricultural and industrial applications",
  },
  {
    id: "2",
    title: "What materials are used in your fencing wire?",
    description:
      "Our fencing wire is typically made from high-quality materials such as:\n* • Galvanized steel for corrosion resistance\n* • Stainless steel for enhanced durability",
  },
  {
    id: "3",
    title: "What are the benefits of using galvanized fencing wire?",
    description:
      "Galvanized fencing wire is resistant to rust and corrosion, making it ideal for outdoor use. It offers long-lasting durability and requires minimal maintenance, ensuring a cost-effective solution for your fencing needs.",
  },
  {
    id: "4",
    title:
      "Can your fencing wire be used for both residential and commercial applications?",
    description:
      "Yes, our fencing wire is versatile and suitable for a wide range of applications, including residential, commercial, agricultural, and industrial settings.",
  },
  {
    id: "5",
    title: "How do I choose the right type of fencing wire for my project?",
    description:
      "Selecting the right fencing wire depends on factors such as:\n* • The intended use (security, livestock containment, decorative)\n* • Local climate and environmental conditions\n* • Desired height and strength of the fence\n* • Aesthetic preferences",
  },
  {
    id: "6",
    title: "Do you provide custom fencing wire solutions?",
    description:
      "Yes, we can customize fencing wire products based on your specific requirements, including different gauges, coatings, and lengths",
  },
];
