import ImageContainer from "components/shared/image-container/image-container.component";
import "./moments.styles.scss";

const images = [
  "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728315331/IMG_4489_s9el9d.jpg",
  "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728315310/IMG_7143_tjssqz.webp",
  "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728315307/IMG_7144_uxfbzm.webp",
  "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728315295/47efe59d-b000-4fd3-85c2-a97321dc22c9_1_i3p4xs.webp",
  "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728315279/IMG_7020_fqfbpb.jpg",
  "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728315291/3dfc8c52-c5dd-4599-b0c7-67f25eeb49aa_f2dkes.webp",
  "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728528894/151_rbrcg2.png",
];

const MemorableMoments = () => {
  return (
    <section className="mm-section">
      <div className="mm-content">
        <h2 className="mm-title">
          Memorable <span>Moments</span>
        </h2>
        <div className="mm-grid">
          {images.map((i) => (
            <div className="mm-image" style={{
              backgroundImage: `url(${i})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }} ></div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MemorableMoments;
