import ImageContainer from "components/shared/image-container/image-container.component";
import Offering from "./offering/offering.component";

import "./tailored-offerings.styles.scss";
import { Offering as IOffering } from "types/models";
import { FC } from "react";

interface Props {
  offering: IOffering[];
  title: string;
  titleHighlight: string;
  image: string;
}

const TailoredOfferings: FC<Props> = ({
  offering,
  title,
  titleHighlight,
  image,
}) => {
  return (
    <section className="to-section">
      <div className="to-content">
        <div className="toc-left">
          <h2 className="to-summary">
            {title}
            <span> {titleHighlight}</span>
          </h2>
          <div className="toc-img">
            <ImageContainer src={image} alt="" width="100%" />
          </div>
        </div>
        <div className="toc-right">
          {offering.map((o) => (
            <Offering key={o.id} title={o.title} description={o.description} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TailoredOfferings;
