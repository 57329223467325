import { AppRoute } from "types/routes.types";

export const appRoutes: Record<string, AppRoute> = {
  home: {
    path: "/",
    title: "Home",
    quickLink: true,
    meta: {
      title: "MPL India: Solar Solutions & Manufacturer ",
      description:
        "MPL India offers solar power solutions, fencing wire, pet preforms, and more. Get a free quote today!",
    },
  },
  about: {
    path: "/about",
    title: "About Us",
    quickLink: true,
    meta: {
      title: "About MPL India: Your Trusted Partner ",
      description: `Learn about MPL India's commitment to quality products and services. We're your one-stop shop for solar & more! `,
    },
  },
  services: {
    path: "/services",
    title: "Solar Services",
    subRoutes: [
      {
        path: "/residential-rooftop-solar-panel",
        title: "Residential Solar",
        meta: {
          title: "Residential Solar Panels | MPL India",
          description: `Save on electricity bills with MPL India's residential rooftop solar panels. Call us to get Free consultation.`,
        },
      },
      {
        path: "/commercial-rooftop-solar",
        title: "Commercial & Industrial Solar",
        meta: {
          title: "Commercial Solar Solutions | MPL India ",
          description:
            "Power your business with efficient commercial & industrial solar systems from MPL India. Get a quote today!",
        },
      },
      {
        path: "/solar-panel-for-housing-society",
        title: "Housing Societies Solar",
        meta: {
          title: "Affordable Solar for Housing Societies",
          description: `Reduce community electricity costs with MPL India's solar solutions for housing societies. Request a free proposal.`,
        },
      },
      // {
      //   path: "/outer-space",
      //   title: "Outer Space Solar",
      // },
      {
        path: "/megawatt-solar-power-plant",
        title: "Megawatt Projects",
        meta: {
          title: "Megawatt Solar Power Plants by MPL India ",
          description: `MPL India delivers high-capacity megawatt solar plants. Get a custom quote for your large-scale project.`,
        },
      },
    ],
    quickLink: true,
  },
  manufacture: {
    path: "/manufacture",
    title: "Product Manufacturing",
    subRoutes: [
      {
        path: "/solar-panels-structure",
        title: "Solar Panels Structure",
        meta: {
          title: "MPL India: Solar Panel Structure Experts",
          description: `Get reliable & durable solar panel structures from MPL India. We offer a variety of mounting options.`,
        },
      },
      {
        path: "/fencing-wires",
        title: "Fencing Wires",
        meta: {
          title: "MPL India: Durable Fencing Wire Solutions",
          description: `Secure your property with MPL India's high-quality fencing wire. Explore our wide range of options.`,
        },
      },
      {
        path: "/pet-pre-form",
        title: "PET Preform",
        meta: {
          title: "Trusted Pet Preform Manufacturer",
          description: `MPL India - Your reliable supplier of pet preforms. Contact us for custom solutions and bulk orders.`,
        },
      },
    ],
    quickLink: true,
  },
  projects: { path: "/projects", title: "Projects", quickLink: true },
  blog: { path: "/blog", title: "Blog", quickLink: true },
  thankYou: { path: "/thank-you", title: "Thank You", quickLink: false },
  contactUs: { path: "/contact-us", title: "Contact Us", quickLink: false },

  // solutions
  commercial: {
    path: "/commercial-rooftop-solar",
    title: "Commercial",
    quickLink: false,
  },
  residential: {
    path: "/residential-rooftop-solar-panel",
    title: "Residential",
    quickLink: false,
  },
  housingSocieties: {
    path: "/solar-panel-for-housing-society",
    title: "Housing Societies",
    quickLink: false,
  },
  megawatt: {
    path: "/megawatt-solar-power-plant",
    title: "Megawatt Projects",
    quickLink: false,
  },

  // manufacture
  petPreform: {
    path: "/pet-pre-form",
    title: "Pet Pre-form",
    quickLink: false,
  },
  solarPanelsStructure: {
    path: "/solar-panels-structure",
    title: "Solar Panels Structure",
    quickLink: false,
  },
  fencingWires: {
    path: "/fencing-wires",
    title: "Fencing Wires",
    quickLink: false,
  },
};

export const externalRoutes = {
  facebook: "https://www.facebook.com/profile.php?id=100093288580589",
  instagram: "https://www.instagram.com/mittal_plastomet_limited/",
  linkedIn:
    "https://www.linkedin.com/company/mittal-plastomet-limited/mycompany/",
  process: "",
};
