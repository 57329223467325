import { BrandWhyUs } from "types/models";
import { IoIosStarOutline } from "react-icons/io";
import { GoThumbsup } from "react-icons/go";
import { FiPhone } from "react-icons/fi";

export const aboutWhyUs: BrandWhyUs[] = [
  {
    icon: <IoIosStarOutline />,
    title: "Expertise",
    description:
      "With decades of experience in solar technology and installation, we bring unparalleled knowledge & reliability to every project, ensuring top-notch service & effective energy solutions for all business types.",
  },
  {
    icon: <GoThumbsup />,
    title: "Quality",
    description:
      "We utilise only high-performance solar panels and components, ensuring long-lasting and efficient energy solutions. Our commitment to quality guarantees maximum energy output and system durability.",
  },
  {
    title: "Support",
    icon: <FiPhone />,
    description:
      "Our comprehensive maintenance and customer service ensure that your solar systems run smoothly. We offer comprehensive support from consultation to maintenance, ensuring your solar power for housing societies performs efficiently.",
  },
];
