import { fencingWires } from "constants/fencing.constants";
import "./wire-offering.styles.scss";
import { FencingWire } from "types/models";
import ImageContainer from "components/shared/image-container/image-container.component";

interface WoCardProps {
  fencingWire: FencingWire;
}

const WoCard: React.FC<WoCardProps> = ({ fencingWire }) => {
  return (
    <div className="wo-card">
      <div className="wo-c-images">
        {fencingWire.images.map((image, index) => (
          <div
            key={index}
            className={`woc-img img${index + 1}`}
          >
            <ImageContainer width='100%' height='100%' src={image} alt='' />
          </div>
        ))}
      </div>
      <div className="woc-details">
        <div className="woc-item-details">
          <p className="woc-i-name">{fencingWire.name}</p>
          <p className="woc-i-description">{fencingWire.description}</p>
          {fencingWire.details.map((detail, index) => (
            <p key={index} className="woc-i-detail">
              {detail}
            </p>
          ))}
        </div>
        {fencingWire.data && (
          <div className="woc-data-container">
            <table className="woc-table">
              <thead>
                <tr>
                  {Object.keys(fencingWire.data).map((key, index) => (
                    <th key={index}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {fencingWire.data[Object.keys(fencingWire.data)[0]].map(
                  (_, index) => (
                    <tr key={index}>
                      {fencingWire.data &&
                        Object.keys(fencingWire.data).map((key) => (
                          <td key={key}>{fencingWire.data?.[key][index]}</td>
                        ))}
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

const WireOffering = () => {
  return (
    <section className="wo-wrapper">
      <div className="wo-container">
        <h2 className="wo-summary">
          Our Fencing Wire <span>Offerings</span>
        </h2>

        <div className="wo-grid">
          {fencingWires.map((fencingWire) => (
            <WoCard key={fencingWire.name} fencingWire={fencingWire} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WireOffering;
