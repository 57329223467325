import { FC, useState } from "react";
import { IoChevronDown } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { AppRoute } from "types/routes.types";

interface Props {
  route: AppRoute;
}

const SideNavLink: FC<Props> = ({ route }) => {
  const [openSubRoutes, setOpenSubRoutes] = useState<boolean>(false);

  const toggleSubRoutes = () => setOpenSubRoutes((prev) => !prev);

  const handleNavigation = (
    evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    route: AppRoute
  ) => {
    if (route.subRoutes?.length) {
      evt.preventDefault();
      toggleSubRoutes();
    }
  };

  return (
    <>
      <NavLink
        className={({ isActive }) =>
          isActive ? "nav-link active" : "nav-link"
        }
        onClick={(e) => handleNavigation(e, route)}
        to={route.path}
      >
        {route.title}
        {!!route.subRoutes?.length && <IoChevronDown />}
      </NavLink>
      {!!route.subRoutes?.length && openSubRoutes && (
        <div className="sub-routes">
          {route.subRoutes?.map((sb) => (
            <NavLink
              className={({ isActive }) =>
                isActive ? "sub-route active" : "sub-route"
              }
              to={sb.path}
            >
              {sb.title}
            </NavLink>
          ))}
        </div>
      )}
    </>
  );
};

export default SideNavLink;
