import { FC, ReactNode } from 'react';

import './attributes.styles.scss';

interface Props {
  children: ReactNode;
  title: string;
}

const Attributes: FC<Props> = ({ children, title }) => {
  return (
    <div className='a-container'>
      <p className='a-title'>{title}</p>
      <div className='a-subtext'>{children}</div>
    </div>
  );
};

export default Attributes;
