import Markdown from "react-markdown";
import "./ceo.styles.scss";

const Ceo = () => {
  return (
    <section className="ceo-section">
      <div className="ceo-row">
        <div className="ceo-left">
          <h3 className="ceo-title">
            Our <span>Director</span>
          </h3>
          <Markdown className="ceo-description">
            {`Dinesh Kumar Mittal, the visionary founder of MPL, is a highly experienced Chartered Accountant with extensive expertise in senior leadership roles. Prior to founding MPL, he served as the Vice Chairman of the prestigious Trident Group, where he honed his strategic and operational acumen. His career also includes holding key executive positions at renowned organizations such as Vardhman Group, Birla Century, and Maral Overseas, where he played a pivotal role in driving growth, innovation, and excellence across diverse industries. His leadership journey reflects a rich blend of financial expertise and executive management, laying the foundation for MPL’s success.`}
          </Markdown>
        </div>

        <div className="ceo-right">
          <div
            className="ceo-img"
            style={{
              backgroundImage: `url(${"https://res.cloudinary.com/dsmpun8yd/image/upload/v1728315301/234dca2c-2567-4088-8da2-033066bfa693_spoc54.webp"})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
          <div className="ceo-texts">
            <p className="ceo-name">C.A. Dinesh Kumar Mittal</p>
            <p className="ceo-role">Director</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ceo;
