import ProjectDetails from "components/project-details/project-details.component";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useProjectService from "services/project/project.hooks";
import { BrandProject } from "types/models";

const ProjectDetailsPage = () => {
  const { projectId } = useParams();

  const { getProjectById, data } = useProjectService();

  useEffect(() => {
    getProjectById(projectId as string);
  }, [projectId]);

  if (!data) return null;

  return <ProjectDetails project={data as BrandProject} />;
};

export default ProjectDetailsPage;
