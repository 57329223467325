import { FC, ReactNode } from "react";

import "./footer-contact-detail.styles.scss";
import classNames from "classnames";

interface Props {
  children: ReactNode;
  title: string;
  className?: string;
}

const FooterContactDetail: FC<Props> = ({ children, title, className }) => {
  return (
    <div className={classNames("f-contact", className)}>
      <p className="f-contact-title">{title}</p>
      <div className="f-contact-detail">{children}</div>
    </div>
  );
};

export default FooterContactDetail;
