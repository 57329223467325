import { BrandProject } from "types/models";
import "./project-details.styles.scss";
import { FC } from "react";

interface Props {
  project: BrandProject;
}

const ProjectDetails: FC<Props> = ({ project }) => {
  return (
    <section className="pd-wrapper">
      <h1 className="pd-title">{project.title}</h1>

      <div
        className="pd-img"
        style={{
          backgroundImage: `url(${project.imageUrl})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />

      <div className="pd-row">
        <div className="pd-content-column">
          <div className="pd-intro">
            <strong>Location: </strong> {project.location}
            <br />
            <strong>capacity: </strong>
            {project.capacity}
          </div>

          <div className="pd-content">
            {project.projectFeatures.map((f) => (
              <div key={f.title}>
                <h4 className="pdc-title">{f.title}</h4>
                <p className="pdc-description">{f.summary}</p>
                <ul className="pdc-highlights">
                  {f.highlights.map((h) => (
                    <li className="pdc-highlight" key={h.title}>
                      <h5 className="pdc-h-title">{h.title}</h5>
                      <p className="pdc-h-description">{h.description}</p>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="pdc-conclusion pd-content">
            <h3 className="pdc-conc-title">Conclusion</h3>
            <p className="pdc-conclusion-text">{project.conclusion}</p>
          </div>
        </div>

        <div className="pd-table-of-content">
          <h5 className="pd-toc-title">Table of Content</h5>
          <ul className="pd-content-list">
            {project.projectFeatures.map((f) => (
              <li key={f.title}>{f.title}</li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default ProjectDetails;
