import "./info-panel.styles.scss";

import { FC } from "react";

import mLogo from "assets/images/M.png";
import Markdown from "react-markdown";

interface Props {
  title: string;
  details: string[];
  image?: string;
}

const InfoPanel: FC<Props> = ({ title, details, image }) => {
  return (
    <section className="info-section">
      <div className="info-content">
        <div>
          <h4 className="info-title">{title}</h4>

          <ul className="info-details">
            {details.map((d) => (
              <li key={d} className="info-detail">
                <Markdown>{d}</Markdown>
              </li>
            ))}
          </ul>
        </div>

        {image && (
          <div
            className="info-image"
            style={{ backgroundImage: `url(${image})` }}
          >
            <div
              className="info-m-tag"
              style={{ backgroundImage: `url(${mLogo})` }}
            ></div>
          </div>
        )}
      </div>
    </section>
  );
};

export default InfoPanel;
