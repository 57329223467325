import { projects } from "data/proejcts.data";

export const fetchProjects = () => {
  return projects;
};

export const fetchProjectById = (id: string) => {
  return projects.find((project) => project.id === id);
};

export const fetchFeaturedProjects = () => {
  return projects.filter((project) => project.featured);
};
