import { FC } from "react";

import { BlogDetail } from "types/models";

import ImageContainer from "components/shared/image-container/image-container.component";

import CardTag from "components/shared/card-tag/card-tag.component";
import { appRoutes } from "constants/routes";
import { Link } from "react-router-dom";
import { formatDate } from "utils/strings.utils";
import "./blog-card.styles.scss";

interface Props {
  blog: BlogDetail;
  showTag?: boolean;
}

const BlogCard: FC<Props> = ({ blog, showTag }) => {
  const { date, title, introduction, imageUrl, id } = blog;

  return (
    <div className="blog-card">
      <div className="bc-image">
        <ImageContainer src={imageUrl} alt={title} width="100%" height="100%" />
      </div>
      <div className="bc-content">
        {showTag && blog.blogType?.title && (
          <div className="bc-tags">
            <CardTag title={blog.blogType?.title.toUpperCase()} />
          </div>
        )}
        <div className="bc-date">{formatDate(date)}</div>
        <h2 className="bc-title">{title}</h2>
        <p className="bc-description">{introduction}</p>
        <Link to={`${appRoutes.blog.path}/${id}`} className="bc-link">
          Read more
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
