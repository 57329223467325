import { FC } from "react";

import "./title-summary.styles.scss";
import Markdown from "react-markdown";

interface Props {
  title: string;
  titleHighlight: string;
  summary: string;
}

const TitleSummary: FC<Props> = ({ title, summary, titleHighlight }) => {
  return (
    <div className="ts-container">
      <h2 className="ts-summary">
        {`${title} `}
        <span>{titleHighlight}</span>
      </h2>
      <Markdown className="ts-description">{summary}</Markdown>
    </div>
  );
};

export default TitleSummary;
