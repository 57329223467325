import { FC } from "react";
import "./team.styles.scss";
import { FaLinkedin } from "react-icons/fa6";
import { Person } from "types/models";
import classNames from "classnames";

interface PersonProps {
  details: Person;
  hideBorder?: boolean;
  className?: string
}

export const PersonDetails: FC<PersonProps> = ({ details, hideBorder, className }) => {
  return (
    <div className={classNames("team-person")}  >
      <div
        className={classNames("team-img", { hideBorder }, className)}
        style={{ backgroundImage: `url(${details.imageUrl})` }}
      ></div>
      <div className="team-texts">
        <p className="team-name">{details.name}</p>
        {details.role && <p className="team-role">{details.role}</p>}
        {details.linkedin && (
          <a
            className="team-linkedin"
            href={details.linkedin}
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin />
          </a>
        )}
      </div>
    </div>
  );
};

const Team = () => {
  return (
    <section className="team-section">
      <div className="team-content">
        <h3 className="team-title">
          Meet Our <span>Team</span>
        </h3>
        <div className="team-grid">
          <PersonDetails
            hideBorder
            details={{
              imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728837269/WhatsApp_Image_2024-10-13_at_21.58.19_c1squi.jpg",
              linkedin: "",
              name: "Vijay Kumar Mittal",
              role: "Head PET Preform",
            }}
          />{" "}
          <PersonDetails
            hideBorder
            details={{
              imageUrl:
                "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728315314/f0b8e0f0-ad64-4b32-aa36-4f99295b493b_2_ve0uao.webp",
              linkedin: "",
              name: "C.A. Deepak Kumar Mittal",
              role: "CFO",
            }}
          />{" "}
          <PersonDetails
            hideBorder
            details={{
              imageUrl:
                "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728315312/IMG_7133_bbjoxt.webp",
              linkedin: "",
              name: "Ar. Charu Mittal",
              role: "Head Solar EPC",
            }}
          />{" "}
          <PersonDetails
            hideBorder
            details={{
              imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728835574/4dfccf33-428f-419a-80f5-76e509c447c4.png",
              linkedin: "",
              name: "Nitin Tiwari",
              role: "Head Solar Structure",
            }}
          />{" "}
          <PersonDetails
            hideBorder
            details={{
              imageUrl: "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728837259/Screenshot_2024-10-13_at_10.02.41_PM_wv8dii.png",
              linkedin: "",
              name: "Navneet Goyal",
              role: "Head Fencing Products",
            }}
          />
          <PersonDetails
            hideBorder
            details={{
              imageUrl:
                "https://res.cloudinary.com/dsmpun8yd/image/upload/v1728315317/IMG_7145_mnogrq.webp",
              linkedin: "",
              name: "Shbham Ojha",
              role: "Head Accounts",
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default Team;
