import callUsBg from "assets/images/residential/callusBg.png";

import Certificates from "components/commercial/certificates/certificates.component";
import Form from "components/commercial/form/form.component";
import OurProjects from "components/commercial/our-projects/our-projects.component";
import WhyUs from "components/commercial/why‐us/why‐us.component";
import Faqs from "components/homepage/faqs/faqs.component";
import RooftopSolutions from "components/residential/rooftop-solutions/rooftop-solutions.component";
import About from "components/shared/about/about.component";
import CallUsBanner from "components/shared/call-us-banner/call-us-banner.component";
import Hero from "components/shared/hero/hero.component";
import ServicesBenefits from "components/shared/service-benefits/service-benefits.component";
import SolarBenefits from "components/shared/solar‐benefits/solar‐benefits.component";
import TailoredOfferings from "components/shared/tailored-offerings/tailored-offerings.component";
import TitleSummary from "components/shared/title-summary/title-summary.component";
import Testimonials from "components/testimonials/testimonials.components";
import {
  residentialBenefits,
  residentialFAQS,
  residentialOfferings,
  residentialSolarBenefits,
  residentialWhyUsStrings,
} from "constants/residential.constants";
import { colors } from "theme/colors";

const Residential = () => {
  return (
    <>
      <Hero
        backgroundUrl="https://res.cloudinary.com/dsmpun8yd/image/upload/v1727523491/pixelcut-export_motsus.webp"
        titlePart1="Solar Panels For"
        titleHighlight="Residential"
      />

      <About
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1727533193/04e73979-8677-4596-a92b-245a783f1d55_2_enpbca.webp"
        title="Residential Rooftop "
        titleHighlight="Solar Panel"
        imagePosition="top"
        summary="Switching to solar energy is an excellent way to reduce electricity costs and contribute to a sustainable environment. With growing demand for clean energy, residential rooftop solar panels have become a popular choice among homeowners. Residential solar systems are designed to harness the power of the sun to generate electricity for homes. The primary components of these systems include solar panels, inverters, and sometimes batteries for energy storage. With advancements in solar technology, today's solar systems for residential houses are more efficient, durable, and affordable than ever before. Solar panels capture sunlight and convert it into direct current (DC) electricity. The inverter then converts the DC electricity into alternating current (AC) that powers your home appliances."
      />

      <SolarBenefits
        title="Key Benefits of "
        titleHighlight="Residential Solar Systems"
        benefits={residentialSolarBenefits}
      />

      <ServicesBenefits
        title="Tailored Residential"
        titleHighlight=" Solar Services"
        benefits={residentialBenefits}
      />

      <TitleSummary
        title="Solar System for Residential Houses:"
        titleHighlight="How It Works"
        summary="When deciding on a solar system for your home, it’s essential to understand how the system functions. Residential solar power installation begins with an assessment of your roof’s structure and sunlight exposure. Ideally, your home should have ample sunlight throughout the day to maximize energy production.
Once the panels are installed, they start generating electricity, which can either be used immediately, stored in batteries, or sent back to the grid. Net metering is a common practice where excess power generated by your system is fed back into the grid, and you receive credits on your utility bill."
      />

      <TailoredOfferings
        title="Components of a "
        titleHighlight="Residential Solar System"
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1727531932/Frame_1597881777_dikukl.webp"
        offering={residentialOfferings}
      />

      <TitleSummary
        title="Residential Solar Panel Installation:"
        titleHighlight="Key Considerations"
        summary="While the idea of installing residential solar panels is appealing, there are several factors to consider before proceeding. First, you’ll want to ensure your roof is in good condition, as the panels will be installed there for 20 to 25 years."
      />

      <ServicesBenefits
        title="Factors to "
        titleHighlight="Keep in Mind"
        benefits={residentialBenefits}
        hideForm
      />

      <RooftopSolutions />

      <TitleSummary
        title="Choosing the Right Residential"
        titleHighlight="Solar Installers in Bhopal"
        summary="Selecting the right company for your residential solar installation is crucial for ensuring a successful project. If you are located in Bhopal, you have access to several experienced residential solar installers who can help you design and install your system."
      />

      <About
        titleSize="small"
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1727543074/IMG_5680_1_vrlmro_1_1_taf0da.png"
        summary={`- **Experience**: Select a company with significant experience in solar panel installations. An experienced company will be more adept at handling potential challenges during installation.

- **Certifications**: Ensure the installers are certified by reputable organizations like the Solar Energy Industries Association (SEIA) or similar recognized local bodies. Certifications indicate a certain level of professionalism and adherence to industry standards.

- **Reputation**: Check customer reviews and ask for references. A company's past work and customer feedback can provide insight into their quality of service.

- **Warranty and Support**: A reliable solar company will provide warranties for both the equipment and the installation process. It's important to understand these terms to ensure long-term support.

- **Local Knowledge**: Choosing a local solar installer in Bhopal is advantageous, as they will be familiar with the local regulations, climatic conditions, and energy requirements specific to the region.`}
        title="What to Look for in"
        titleHighlight="Residential Solar Installers"
        backgroundColor={colors.backgroundGrey}
      />

      <CallUsBanner bgImage={callUsBg} />
      <WhyUs
        title="Why Choose"
        titleHighlight="Mittal Plastomet?"
        summary="Making the switch to residential rooftop solar is a wise decision for homeowners looking to reduce energy costs, increase property value, and contribute to a greener future. Whether you're considering a solar system for your home or looking for residential solar installers near you, investing in solar energy is a smart move.
With the increasing affordability of solar panels for home, there has never been a better time to take advantage of solar energy. Not only will you save on electricity bills, but you'll also be doing your part to combat climate change by reducing your reliance on fossil fue"
        whyUsDetails={residentialWhyUsStrings}
      />
      <Certificates />
      <OurProjects />
      <Testimonials hideCertificates showImage />
      <Faqs faqs={residentialFAQS} />
      <Form />
    </>
  );
};

export default Residential;
