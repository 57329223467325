import Form from "components/commercial/form/form.component";
import AllProjects from "components/projects/all-projects/all-projects.component";
import Description from "components/projects/description/description.component";
import Hero from "components/shared/hero/hero.component";

const Projects = () => {
  return (
    <>
      <Hero
        titleHighlight="Projects"
        titlePart1="Our Solar Installation"
        backgroundUrl="https://res.cloudinary.com/dsmpun8yd/image/upload/v1728354161/IMG_5873_1_udul63_rqsxdr_1_asduhi.webp"
      />
      <Description />
      <AllProjects />
      <Form />
    </>
  );
};

export default Projects;
