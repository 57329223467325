import Form from "components/commercial/form/form.component";
import Blogs from "components/homepage/blogs/blogs.component";
import Faqs from "components/homepage/faqs/faqs.component";
import MachinerySetup from "components/machinery-setup/machinery-setup.component";
import About from "components/shared/about/about.component";
import Hero from "components/shared/hero/hero.component";
import InfoPanel from "components/shared/info-panel/info-panel.component";
import TitleSummary from "components/shared/title-summary/title-summary.component";
import OtherProducts from "components/solar-panel-structure/other-products/other-products.component";
import StructureOfferings from "components/solar-panel-structure/structure-offerings/structure-offerings.component";
import Testimonials from "components/testimonials/testimonials.components";
import { solarStructureFaqs } from "constants/struture.constants";

const SolarPanelStructure = () => {
  return (
    <>
      <Hero
        backgroundUrl="https://res.cloudinary.com/dsmpun8yd/image/upload/v1727834726/ground-mount-gi-structure_iwyvoy.jpg"
        titlePart1="Solar Panels"
        titleHighlight="Structure"
      />
      <About
        title="Engineering Excellence in"
        titleHighlight="Solar Panel Structure"
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1727802451/solar-panel-mounting-structure-strut-channel-500x500_xp0hba.webp"
        summary={`Solar panel mounting structures are critical to the efficiency and durability of your solar energy system. At Mittal Plastomet Ltd., we design and manufacture high-quality mounting structures that are engineered for strength, durability, and ease of installation. Our structures are built to withstand extreme weather conditions, ensuring that your solar panels remain securely in place for years. Whether you need ground-mounted or rooftop installations, our custom solutions cater to various applications, including residential, commercial, and industrial projects.`}
      />
      <TitleSummary
        title="Solar Panel Mounting"
        titleHighlight="Structure Manufacturer"
        summary="A solar panel mounting structure, also known as a solar mounting system or solar module mounting structure, is a crucial component that securely holds solar panels in place. This structure ensures optimal positioning for maximum sun exposure and withstands various weather conditions over the lifespan of the system."
      />
      <StructureOfferings />
      <MachinerySetup
        content="MPL India: Your Partner in Solar Panel Mounting Solutions offers comprehensive solar panel structure solutions through our partnerships with leading manufacturers. We understand the importance of choosing the right structure for your specific needs, ensuring:
* **Compatibility with your chosen solar panels.**
* **Compliance with local building codes and safety regulations.**
* **Optimal performance and long-term durability.**"
        counts={[
          { count: "15+", title: "Items" },
          { count: "300 Tons", title: "Production Per Month" },
          { count: "50+", title: "Team Members" },
        ]}
        title="Our Machinery"
        titleHighlight="Setup"
        imageUrl="https://res.cloudinary.com/dsmpun8yd/image/upload/v1722829939/IMG_5761_rtapda.jpg"
      />
      <InfoPanel
        details={[
          "MPL India collaborates with a network of reliable solar panel structure manufacturers in Bhopal and across India. We prioritize partnering with manufacturers who prioritize:",
          `* **High-quality materials and construction standards**
  * **Innovative design solutions**
  * **Competitive pricing**
  * **Excellent customer service**`,
          "By partnering with MPL India, you gain access to a wide range of solar panel mounting structures from various manufacturers. We will help you find the perfect solution that meets your specific project requirements and budget.",
        ]}
        title="Solar Panel Structure Manufacturers Bhopal"
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1727804395/IMG_7393_1_f64y58.webp"
      />
      <OtherProducts skipProduct="solarPanels" />
      <Testimonials />
      <Form />
      <Faqs faqs={solarStructureFaqs} />
      <Blogs />
    </>
  );
};

export default SolarPanelStructure;
