import { Input } from "@material-tailwind/react";
import "./service-benefits.styles.scss";
import { ChangeEvent, FC, FormEventHandler, useRef, useState } from "react";
import CustomButton from "components/shared/buttons/custom-button/custom-button.component";
import { MdArrowRightAlt } from "react-icons/md";
import { notify } from "utils/toast.utils";
import { ServiceBenefit } from "types/services.types";
import classNames from "classnames";

interface Props {
  title: string;
  titleHighlight: string;
  benefits: ServiceBenefit[];
  hideForm?: boolean;
  titleSize?: "regular" | "small";
}

const ServicesBenefits: FC<Props> = ({
  title,
  titleHighlight,
  benefits,
  hideForm,
  titleSize = "regular",
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formDetails, setFormDetails] = useState<any>({});

  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();
    Object.keys(formDetails).forEach((key) => {
      formData.append(key, formDetails[key]);
    });

    try {
      await fetch(
        "https://script.google.com/macros/s/AKfycbz3yuC7lNUnleQvL8Gky9sX6D2jvm-abrNSvXFLlRJFIHdoc2qpT_eWQEocxJMIpPgI/exec",
        {
          method: "POST",
          body: formData,
        }
      );

      formRef.current?.reset();
      notify("We have received your details", "success");
    } catch (error) {
      notify("Something went wrong", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: ChangeEvent) => {
    const { value, id } = e.target as HTMLInputElement;

    setFormDetails((prev: any) => ({ ...prev, [id]: value }));
  };

  return (
    <section className="trs-section">
      <div className="trs-content">
        <h2 className={classNames("trs-summary", titleSize)}>
          {`${title} `}
          <span>{titleHighlight}</span>
        </h2>
        <div className="trs-content-row">
          <div className="trs-left">
            {benefits.map((b) => (
              <div key={b.title} className="trs-detail">
                <h3 className="trs-detail-title">{b.title}</h3>
                <p className="trs-detail-summary">{b.description}</p>
              </div>
            ))}
          </div>

          <div className="trs-right">
            {!hideForm && (
              <form className="trs-form" ref={formRef} onSubmit={handleSubmit}>
                <h4 className="trs-form-title">Request a call</h4>
                <div>
                  <p className="form-input-label">Full Name *</p>
                  <Input
                    id="Name"
                    variant="outlined"
                    placeholder="Full Name"
                    color="white"
                    required
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                    crossOrigin={false}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p className="form-input-label">Phone Number *</p>
                  <Input
                    onChange={handleChange}
                    id="Number"
                    variant="outlined"
                    label=""
                    color="white"
                    required
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                    crossOrigin={false}
                  />
                </div>
                <div>
                  <p className="form-input-label">Email *</p>
                  <Input
                    variant="outlined"
                    label=""
                    id="Email"
                    color="white"
                    required
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                    onChange={handleChange}
                    crossOrigin={false}
                  />
                </div>
                <div className="submit-btn">
                  <CustomButton
                    style={{ width: "100%" }}
                    loading={loading}
                    endIcon={
                      <MdArrowRightAlt fontSize="20px" color="inherit" />
                    }
                    color="primary"
                  >
                    Submit
                  </CustomButton>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesBenefits;
