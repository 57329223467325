import earthBgUrl from "assets/images/commercial/earth.png";

import About from "components/shared/about/about.component";
import Certificates from "components/commercial/certificates/certificates.component";
import Form from "components/commercial/form/form.component";
import OurProjects from "components/commercial/our-projects/our-projects.component";
import WhyUs from "components/commercial/why‐us/why‐us.component";
import Faqs from "components/homepage/faqs/faqs.component";
import CallUsBanner from "components/shared/call-us-banner/call-us-banner.component";
import Hero from "components/shared/hero/hero.component";
import Testimonials from "components/testimonials/testimonials.components";
import {
  housingBenefits,
  housingOfferings,
  housingServiceBenefits,
  housingSocietiesFAQS,
  housingSocietiesWhyUsStrings,
} from "constants/housing-societies.constants";
import ServicesBenefits from "components/shared/service-benefits/service-benefits.component";
import TitleSummary from "components/shared/title-summary/title-summary.component";
import TailoredOfferings from "components/shared/tailored-offerings/tailored-offerings.component";
import InfoPanel from "components/shared/info-panel/info-panel.component";
import SolarBenefits from "components/shared/solar‐benefits/solar‐benefits.component";

const HousingSocieties = () => {
  return (
    <>
      <Hero
        backgroundUrl="https://res.cloudinary.com/dsmpun8yd/image/upload/v1727775548/HS_daq7pb.webp"
        titlePart1="Solar Panels For"
        titleHighlight="Housing Societies"
      />
      <About
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1728838280/Subtract-3_ejcglr.png"
        title="Empowering Housing Societies with"
        titleHighlight="Solar Energy"
        summary={`Solar power for housing societies is more than just an energy solution; it's a step towards a sustainable future. By installing solar panels for housing societies, residents can harness clean, renewable energy right from their rooftops. Solar energy for housing societies not only reduces electricity bills but also promotes a greener environment, making your society a model for sustainable living.`}
      />
      <TitleSummary
        title="Solar Power System for"
        titleHighlight="Housing Society"
        summary="Implementing a solar power system for housing society is not just a trend; it is an essential step toward energy independence and environmental responsibility. By harnessing solar energy, housing societies can significantly reduce their carbon footprints and enjoy substantial savings on energy bills.  The deployment of solar power for housing societies is a multifaceted process that involves careful planning and consideration of the community’s specific energy needs. At Mittal Plastomet Ltd., we provide tailored solar solutions for group housing societies, ensuring that each system is optimized for performance and efficiency."
      />
      <ServicesBenefits
        benefits={housingBenefits}
        title="Benefit of Solar Power for "
        titleHighlight="Housing Societies"
      />
      <About
        flip
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1728838280/Subtract-3_ejcglr.png"
        title="Recover Your Solar"
        titleHighlight="Investment Efficiently"
        summary={`Investing in solar power for housing societies is a wise decision that pays off over time. The affordability of solar for housing societies combined with available government subsidies makes it easier to recover your investment. Affordable solar for residential complexes ensures that your upfront costs are minimized, and the returns on investment are maximized through consistent energy savings.`}
      />
      <CallUsBanner bgImage={earthBgUrl} />
      <WhyUs
        summary="We tailor our solar power systems to fit the specific requirements of each housing society, considering factors such as roof space and energy consumption.
 Our systems are built with high-quality solar panels and components to guarantee longevity and performance.
"
        whyUsDetails={housingSocietiesWhyUsStrings}
      />
      <Certificates />
      <TitleSummary
        title="Solar System for Housing Society
"
        titleHighlight=": Tailored Solutions"
        summary="Implementing a solar power system for housing society is not just a trend; it is an essential step toward energy independence and environmental responsibility. By harnessing solar energy, housing societies can significantly reduce their carbon footprints and enjoy substantial savings on energy bills.  The deployment of solar power for housing societies is a multifaceted process that involves careful planning and consideration of the community’s specific energy needs. At Mittal Plastomet Ltd., we provide tailored solar solutions for group housing societies, ensuring that each system is optimized for performance and efficiency."
      />
      <TailoredOfferings
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1727777970/residential-house-with-rooftop-covered-with-solar-photovoltaic-panels-producing-clean-ecological-electrical-energy-suburban-rural-area-concept-autonomous-home__1_-removebg-preview_1_cjko0m.webp"
        offering={housingOfferings}
        title="Steps to Implement a"
        titleHighlight="Solar System"
      />
      <InfoPanel
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1727777118/1_1_pat6p8.webp"
        title="Solar Power for Housing Societies in Bhopal"
        details={[
          "Bhopal, with its abundant sunshine, is an ideal location for solar energy implementation. Solar power for housing societies in Bhopal presents a fantastic opportunity for communities to become more energy-efficient and environmentally conscious.",
        ]}
      />
      <ServicesBenefits
        benefits={housingServiceBenefits}
        title="Benefits of Rooftop"
        titleHighlight="Solar System"
        hideForm
      />
      <OurProjects />
      <Testimonials hideCertificates showImage />
      <Faqs faqs={housingSocietiesFAQS} />
      <Form />
    </>
  );
};

export default HousingSocieties;
