import { BrandFaq, SolarStructure } from "types/models";

export const structureOfferings: SolarStructure[] = [
  // Purlin
  {
    name: "Purlin",
    id: 0,
    image:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727785382/Rectangle_6499_yplgao.png",
    attributes: [
      "Dimension - 41x41x41mm",
      "Thickness - 2.00mm",
      "Weight - 13kg for 6.4 meters length",
    ],
  },
  // Rafter
  {
    name: "Rafter",
    id: 1,
    image:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727785385/Rectangle_6503_r8o2ok.png", // Assuming no specific image
    attributes: [
      "Dimension - 80x40x15mm",
      "Thickness - 2.00mm",
      "Weight - 17kg for 8.4 meters length",
    ],
  },
  // Base Plate C Clip
  {
    name: "Base Plate C Clip",
    id: 2,
    image:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727785388/Rectangle_6504_ttwmmc.png", // Assuming no specific image
    attributes: [
      "Dimension - 150x150x150mm",
      "Thickness - 3.00mm",
      "Weight - 720 gms",
    ],
  },
  // Base Plate Purlin
  {
    name: "Base Plate Purlin",
    id: 3,
    image:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727785389/Rectangle_6505_qi89ct.png", // Assuming no specific image
    attributes: [
      "Dimension - 150x150mm",
      "Thickness - 2.50mm",
      "Weight - 620 gms",
    ],
  },
  // Mid Clamp
  {
    name: "Mid Clamp",
    id: 4,
    image:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727785392/Rectangle_6506_ucfbez.png", // Assuming no specific image
    attributes: [
      "Dimension - 4605mm",
      "Thickness - 2.50mm",
      "Weight - 115 gms",
    ],
  },
  // End Clamp
  {
    name: "End Clamp",
    id: 5,
    image:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727785395/Rectangle_6507_lk3cxu.png", // Assuming no specific image
    attributes: ["Dimension - 7800mm", "Thickness - 2.50mm", "Weight - 95 gms"],
  },
  // Spring Bolt & Allen Nut
  {
    name: "Spring Bolt & Allen Nut",
    id: 6,
    image:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727785368/Rectangle_6508_d4vskh.png", // Assuming no specific image
    attributes: ["Thickness - 10.00mm"], // Assuming no specific dimension and weight
  },
  // M 10x25 Mm Nut & Bolt with 2 Washers
  {
    name: "M 10x25 Mm Nut & Bolt with 2 Washers",
    id: 7,
    image:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727785369/Rectangle_6509_veshxf.png", // Assuming no specific image
    attributes: ["Thickness - 10.00mm"], // Assuming no specific dimension and weight
  },
  // M 8x25 Mm Nut & Bolt with 2 Washers
  {
    name: "M 8x25 Mm Nut & Bolt with 2 Washers",
    id: 8,
    image:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727785371/Rectangle_6510_zs0mi9.png", // Assuming no specific image
    attributes: ["Thickness - 8.00mm"], // Assuming no specific dimension and weight
  },
  // Jointer Purlin 5.5" Length
  {
    name: 'Jointer Purlin 5.5" Length',
    id: 9,
    image:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727785373/Rectangle_6511_shlv0y.png", // Assuming no specific image
    attributes: [
      'Dimension - 5.5" Long',
      "Thickness - 3.00mm",
      "Weight - 200 gms",
    ],
  },
  {
    name: 'Jointer Purlin 8" Length',
    id: 10,
    image:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727785373/Rectangle_6511_shlv0y.png", // Assuming no specific image
    attributes: [
      'Dimension - 8" Long',
      "Thickness - 3.00mm",
      "Weight - 400 gms",
    ],
  },
  // Jointer C Lip
  {
    name: "Jointer C Lip",
    id: 11,
    image:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727785375/Rectangle_6513_q0xusx.png", // Assuming no specific image
    attributes: [
      "Dimension - 6 Long",
      "Thickness - 2.50mm",
      "Weight - 400 gms",
    ],
  },
  // Degree Hanger
  {
    name: "Degree Hanger",
    id: 12,
    image:
      "https://res.cloudinary.com/dsmpun8yd/image/upload/v1727785380/Rectangle_6515_dllwau.png", // Assuming no specific image
    attributes: ["Thickness - 2.50mm", "Weight - 175 gms"], // Assuming no specific dimension
  },
];

export const solarStructureFaqs: BrandFaq[] = [
  {
    id: '1',
    title: 'What are GI solar mounting structures?',
    description: 'GI (Galvanized Iron) solar mounting structures are robust frameworks used to securely hold solar panels in place. They are designed to withstand environmental stresses while providing long-lasting support for solar installations.',
  },
  {
    id: '2',
    title: 'What are the benefits of using GI for solar mounting structures?',
    description: 'GI offers several advantages, including:\n* **Corrosion Resistance:** The galvanization process protects against rust and extends the lifespan of the structure.\n* **Strength and Durability:** GI provides strong support, making it suitable for various weather conditions.\n* **Cost-Effectiveness:** GI is often more affordable than other materials, offering a good balance between performance and cost.',
  },
  {
    id: '3',
    title: 'What types of solar mounting structures do you offer?',
    description: 'We provide a variety of mounting solutions, including:\n* Ground-mounted systems\n* Roof-mounted systems\n* Solar carports\n* Custom solutions tailored to specific project needs',
  },
  {
    id: '4',
    title: 'How do I choose the right mounting structure for my solar project?',
    description: 'The choice of mounting structure depends on factors such as:\n* The type of solar installation (residential, commercial, or utility scale)\n* Ground conditions and soil type\n* Roof type and orientation\n* Local climate and wind load requirements',
  },
  {
    id: '5',
    title: 'Are your mounting structures compatible with all solar panel brands?',
    description: 'Yes, our GI solar mounting structures are designed to be compatible with most solar panel brands and configurations. We can also customize solutions as needed.',
  },
];