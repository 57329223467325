import { IoIosAddCircleOutline } from 'react-icons/io';

import './faq.styles.scss';
import { FC, useState } from 'react';
import { BrandFaq } from 'types/models';
import classNames from 'classnames';
import Markdown from 'react-markdown';

interface Props {
  faq: BrandFaq;
}

const Faq: FC<Props> = ({ faq }) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleFaq = () => setOpen((prev) => !prev);

  return (
    <div className={classNames('faq', { open })}>
      <div onClick={toggleFaq} className='faq-title-row'>
        <p className='faq-title'>{faq.title}</p>
        <IoIosAddCircleOutline
          className={classNames('faq-btn', { 'open-btn': open })}
        />
      </div>
      <div className={classNames('faq-summary', { 'open-summary': open })}>
        <Markdown className='f-text'>{faq.description}</Markdown>
      </div>
    </div>
  );
};

export default Faq;
