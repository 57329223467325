import BlogDetails from "components/blog-details/blog-details.component";
import RelatedBlogs from "components/blog-details/related-blogs/related-blogs.component";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useBlogService from "services/blog/blog.hooks";
import { BlogDetail } from "types/models";

const BlogDetailsPage = () => {
  const { blogId } = useParams();

  const { data: blog, getBlogById } = useBlogService();

  useEffect(() => {
    getBlogById(blogId as string);
  }, []);

  if(!blog) return null;

  return (
    <>
      <BlogDetails blog={(blog as BlogDetail)!} />
      <RelatedBlogs blogType={(blog as BlogDetail).blogType!} />
    </>
  );
};

export default BlogDetailsPage;
