import BenefitCard from "./benefit-card/benefit-card.component";

import { FC } from "react";
import { Benefit } from "types/models";
import "./solar‐benefits.styles.scss";
import classNames from "classnames";

interface Props {
  benefits: Benefit[];
  title: string;
  titleHighlight: string;
  titleSize?: "regular" | "small";
}

const SolarBenefits: FC<Props> = ({
  benefits,
  title,
  titleHighlight,
  titleSize = "regular",
}) => {
  return (
    <section className="sb-section">
      <div className="sb-content">
        <div className="sb-texts">
          <h2 className={classNames("sb-summary", titleSize)}>
            {title} <span>{titleHighlight}</span>
          </h2>
        </div>

        <div className="benefits-cards">
          {benefits.map((b, i) => (
            <BenefitCard key={b.id} benefit={b} index={i + 1} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SolarBenefits;
