import Attributes from "components/homepage/about-us/attributes/attributes.component";
import "./endless-growth.styles.scss";

const EndlessGrowth = () => {
  return (
    <section className="eg-section">
      <div className="eg-row">
        <div className="eg-imgs-grid">
          <div className="eg-img img1" style={{backgroundImage: `url(${'https://res.cloudinary.com/dsmpun8yd/image/upload/v1728528419/4371_z61pc0.webp'})`, backgroundPosition: 'center', backgroundSize:'cover'}} ></div>
          <div className="eg-img img2" style={{backgroundImage: `url(${'https://res.cloudinary.com/dsmpun8yd/image/upload/v1722829941/IMG_5760_dgi5pl.jpg'})`, backgroundPosition: 'center', backgroundSize:'cover'}} ></div>
          <div className="eg-img img3" style={{backgroundImage: `url(${'https://res.cloudinary.com/dsmpun8yd/image/upload/v1728297573/IMG_7266_1_1_xwprnt.webp'})`, backgroundPosition: 'center', backgroundSize:'cover'}} ></div>
        </div>
        <div className="eg-right">
          <div>
            <div className="eg-title">
              Reaching for <span>Endless Growth</span>
            </div>
            <div className="eg-description">
              At Mittal Plastomet, our achievements are defined by measurable
              success. With 100+ projects completed in various sectors, we have
              created an extensive portfolio of satisfied clients. Our expertise
              in solar solutions spans across 5 states, making us a trusted name
              in renewable energy. As a team, we’ve facilitated energy savings
              equivalent to planting 50,000 trees, showing our tangible
              contribution to sustainability.
            </div>
          </div>
          <div className="eg-numbers">
            <Attributes title="10+ years">of excellence in manufacturing & service</Attributes>
            <Attributes title="500+">Happy Clients across diverse sectors</Attributes>
            <Attributes title="3+">Segments of manufacturing operations</Attributes>
            <Attributes title="EPC">End to End Solutions for Solar, Preform & Fencing</Attributes>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EndlessGrowth;
