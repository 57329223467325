import { blogs } from "data/blogs.data";

export const fetchBlogs = () => {
  return blogs;
};

export const fetchBlogById = (id: string) => {
  return blogs.find((blog) => blog.id === id);
};

export const fetchLatestBlogs = (limit: number = 5) => {
  return blogs.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).slice(0, limit);
};
