import earthBgUrl from "assets/images/commercial/earth.png";

import About from "components/shared/about/about.component";
import Certificates from "components/commercial/certificates/certificates.component";
import Form from "components/commercial/form/form.component";
import OurProjects from "components/commercial/our-projects/our-projects.component";
import WhyUs from "components/commercial/why‐us/why‐us.component";
import Faqs from "components/homepage/faqs/faqs.component";
import CallUsBanner from "components/shared/call-us-banner/call-us-banner.component";
import Hero from "components/shared/hero/hero.component";
import Testimonials from "components/testimonials/testimonials.components";
import {
  housingBenefits,
  housingSocietiesFAQS,
  housingSocietiesWhyUsStrings,
} from "constants/housing-societies.constants";
import ServicesBenefits from "components/shared/service-benefits/service-benefits.component";
import {
  megawattFaqs,
  megawattProjectsBenefits,
  megawattWhyUsStrings,
  mwSolarBenefits,
} from "constants/megawatt-projects.constants";
import TitleSummary from "components/shared/title-summary/title-summary.component";
import SolarBenefits from "components/shared/solar‐benefits/solar‐benefits.component";
import InfoPanel from "components/shared/info-panel/info-panel.component";

const MegawattProjects = () => {
  return (
    <>
      <Hero
        backgroundUrl="https://res.cloudinary.com/dsmpun8yd/image/upload/v1728839569/Rectangle_6440-2_pmxhf1.png"
        titlePart1="Solar Power Plant"
        titleHighlight="Megawatt Projects"
      />
      <About
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1728838744/Subtract-5_wslpbb.png"
        title="Overview of Megawatt-Scale"
        titleHighlight="Solar Power Plants"
        summary={`Megawatt-scale solar power plants are large systems designed to generate a substantial amount of electricity, which is often fed directly into the grid. These plants are crucial for meeting the energy needs of big businesses and utility companies, offering a sustainable and cost-effective alternative to traditional energy sources. By harnessing solar energy on a large scale, these plants help reduce carbon emissions and support environmental sustainability. They play a vital role in India's renewable energy future.`}
      />
      <ServicesBenefits
        benefits={megawattProjectsBenefits}
        title="Benefit of Solar Power for"
        titleHighlight="Megawatt"
      />
      <TitleSummary
        title="MW Solar Power Plant Manufacturers and Installation in Bhopal"
        titleHighlight="Manufacturers and Installation in Bhopal"
        summary="Are you considering a megawatt solar power plant for your industrial facility or commercial venture in Bhopal? MPL India is here to guide you. We collaborate with leading MW solar power plant manufacturers across India, ensuring access to the latest technology and top-quality equipment. We also provide comprehensive installation services, ensuring your MW solar plant is set up efficiently and professionally."
      />
      <CallUsBanner bgImage={earthBgUrl} />
      <SolarBenefits
        title="Setting Up a"
        titleHighlight="MW Solar Power Pant"
        benefits={mwSolarBenefits}
      />
      <InfoPanel
        title="Megawatt Solar Power Plant Installation Services"
        image="https://res.cloudinary.com/dsmpun8yd/image/upload/v1727783557/ssss_zrgxcl.webp"
        details={[
          "**Site Preparation:** We prepare the designated area for efficient installation, including ground leveling and foundation work (if necessary).",
          "**Panel Installation:** Our skilled technicians meticulously install the MW solar panels to maximize sun exposure and ensure structural integrity.",
          "**Inverter Installation:** We install and configure the inverters to convert direct current (DC) electricity generated by the panels into usable alternating current (AC) electricity.",
          "**Electrical Wiring and Cabling:** We meticulously install electrical wiring and cabling to connect components and ensure efficient power transmission.",
          "**Monitoring and Maintenance:** We provide ongoing monitoring and maintenance services to ensure your MW solar power plant functions optimally throughout its lifespan.",
        ]}
      />
      <WhyUs whyUsDetails={megawattWhyUsStrings} />
      <Certificates />
      <OurProjects />
      <Faqs faqs={megawattFaqs} />
      <Form />
    </>
  );
};

export default MegawattProjects;
