import { FC } from "react";
import "./text-with-image.styles.scss";
import Markdown from "react-markdown";
import classNames from "classnames";

interface Props {
  title: string;
  titleHighlight: string;
  text: string;
  image?: string;
  textAlign?: 'left' | 'center' | 'right';
}

const TextWithImage: FC<Props> = ({ title, titleHighlight, text, image, textAlign }) => {
  return (
    <section className="twi-wrapper">
      <div className="twi-container">
        <h2 className="twi-summary">
          {`${title} `} <span>{titleHighlight}</span>
        </h2>
        <Markdown className={classNames('twi-description', textAlign)}>{text}</Markdown>
      </div>

      <div
        className="twi-img"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
    </section>
  );
};

export default TextWithImage;
