import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { IoMenuSharp } from "react-icons/io5";

import brandLogoUrl from "assets/images/brand-logo.png";
import { appRoutes } from "constants/routes";
import { brandName } from "constants/strings.constants";

import ImageContainer from "components/shared/image-container/image-container.component";
import ContactUsButton from "components/shared/contact-us-button/contact-us-button.component";

import "./header.styles.scss";
import { useNav } from "providers/nav.provider";
import HeaderLink from "./header-link/header-link.component";

const Header = () => {
  const { toggleSideNav } = useNav();

  const navRef = useRef<HTMLElement>(null);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0 || location.pathname === "/thank-you";

      if (isScrolled) {
        navRef.current?.classList.add("scrolled");
      } else {
        navRef.current?.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  useEffect(() => {
    if (location.pathname === "/thank-you")
      navRef.current?.classList.add("scrolled");
    else
    navRef.current?.classList.remove("scrolled");
  }, [location.pathname]);

  return (
    <>
      <nav ref={navRef} className="header-container">
        <div className="header">
          <NavLink to={appRoutes.home.path}>
            <div className="h-brand">
              <ImageContainer
                height="32px"
                width="44.05px"
                src={brandLogoUrl}
                alt={brandName}
                objectFit="contain"
              />
              <p className="h-brand-name">{brandName}</p>
            </div>
          </NavLink>

          <IoMenuSharp
            size="32px"
            className="h-mobile-menu"
            onClick={toggleSideNav}
          />

          <ul className="links">
            {Object.values(appRoutes)
              .filter((r) => r.quickLink)
              .map((route) => (
                <li key={route.path} className="nav-link">
                  <HeaderLink route={route} />
                </li>
              ))}
          </ul>

          <div className="h-cta">
            <ContactUsButton />
          </div>
        </div>
      </nav>
      <div className="nav-clearing"></div>
    </>
  );
};

export default Header;
