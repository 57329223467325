import CustomButton from "components/shared/buttons/custom-button/custom-button.component";
import { appRoutes } from "constants/routes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useBlogService from "services/blog/blog.hooks";
import { BlogDetail } from "types/models";
import BlogCard from "./blog-card/blog-card.component";
import "./blogs.styles.scss";

const Blogs = () => {
  const navigate = useNavigate();

  const { data: blogs, getLatestBlogs } = useBlogService();

  useEffect(() => {
    getLatestBlogs();
  }, []);

  console.log(blogs)

  return (
    <section className="blogs-section">
      <div className="blogs-row">
        <p className="blogs-title">BLOGS</p>
        <h2 className="blogs-summary">
          Stay Updated with <span>Our Latest Insights</span>
        </h2>

        <div className="blogs-grid">
          {(blogs as BlogDetail[])?.map((b) => (
            <BlogCard key={b.id} blog={b} />
          ))}
        </div>
        <div className="b-cta-row">
          <CustomButton onClick={() => navigate(appRoutes.blog.path)}>
            View All
          </CustomButton>
        </div>
      </div>
    </section>
  );
};

export default Blogs;
